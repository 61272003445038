import React from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { XXL } from '@zendeskgarden/react-typography';
import { Tiles } from '@zendeskgarden/react-forms';

import { useEditorContext } from '../contexts';
import { LeafIcon } from '../Icons';

// eslint-disable-next-line react/prop-types
export function ExtensionTile({ name, description }) {
  return (
    <Tiles name={name}>
      <Tiles.Tile value={name}>
        <Tiles.Icon style={{ display: 'flex', justifyContent: 'center' }}>
          <LeafIcon />
        </Tiles.Icon>
        <Tiles.Label>{name}</Tiles.Label>
        <Tiles.Description>{description}</Tiles.Description>
      </Tiles.Tile>
    </Tiles>
  );
}

export function ExtensionsEditor() {
  const { extensions } = useEditorContext();

  return (
    <Grid>
      <Row
        justifyContent="center"
        style={{ marginTop: '24px', marginBottom: '24px' }}
      >
        <XXL>Extensions</XXL>
      </Row>

      {React.Children.toArray(
        // eslint-disable-next-line react/jsx-key
        extensions
          .reduce((acc, extension, index) => {
            const mod = index % 4;

            if (mod === 0) {
              acc.push([]);
            }

            acc[acc.length - 1].push(extension);

            return acc;
          }, [])
          .map((group) => (
            // eslint-disable-next-line react/jsx-key
            <Row style={{ padding: '12px' }}>
              {React.Children.toArray(
                group.map((extension) => (
                  // eslint-disable-next-line react/jsx-key
                  <Col sm={6} md={4} lg={3}>
                    <ExtensionTile {...extension} />
                  </Col>
                )),
              )}
            </Row>
          )),
      )}
    </Grid>
  );
}
