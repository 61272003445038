{
  "graph": {
    "2PiawIHWEC": {
      "x": 1017.1005661423137,
      "y": -11.521705421241549,
      "type": "view",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "kxMDO2czNY", "portName": "node" }] },
        "outputs": {}
      },
      "inputData": {
        "node": {},
        "title": { "string": "" },
        "description": { "string": "" }
      },
      "root": true,
      "id": "2PiawIHWEC"
    },
    "ijokrA-jDU": {
      "id": "ijokrA-jDU",
      "x": -1020.1363387614831,
      "y": -285.4546667273755,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "string": [{ "nodeId": "0HaFAProMJ", "portName": "node" }] }
      },
      "inputData": { "string": { "string": "Anchor" } }
    },
    "0HaFAProMJ": {
      "id": "0HaFAProMJ",
      "x": -764.9135280299827,
      "y": -165.82641601562491,
      "type": "Anchor",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "ijokrA-jDU", "portName": "string" }] },
        "outputs": { "node": [{ "nodeId": "-uI0E6vg5G", "portName": "node" }] }
      },
      "inputData": {
        "node": {},
        "href": { "string": "" },
        "isDanger": { "boolean": false },
        "isExternal": { "boolean": false }
      }
    },
    "IVnw3Q9XWt": {
      "id": "IVnw3Q9XWt",
      "x": -1027.0288415857258,
      "y": -112.67122835726346,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "string": [{ "nodeId": "vsFxg9ZXBl", "portName": "node" }] }
      },
      "inputData": { "string": { "string": "Button" } }
    },
    "-uI0E6vg5G": {
      "id": "-uI0E6vg5G",
      "x": -148.02736376152654,
      "y": -200.57863329277654,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "0HaFAProMJ", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "wXMY_RFw62", "portName": "node-2" }] }
      },
      "inputData": { "node": {} }
    },
    "Efo4QHemR8": {
      "id": "Efo4QHemR8",
      "x": -149.45528624487696,
      "y": -79.03587466380631,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "vsFxg9ZXBl", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "wXMY_RFw62", "portName": "node-3" }] }
      },
      "inputData": { "node": {} }
    },
    "KkO6ji88We": {
      "id": "KkO6ji88We",
      "x": 504.6941121419268,
      "y": -163.01714579264313,
      "type": "Row",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "wXMY_RFw62", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "kxMDO2czNY", "portName": "node" }] }
      },
      "inputData": { "node": {} }
    },
    "kxMDO2czNY": {
      "id": "kxMDO2czNY",
      "x": 742.4026076858104,
      "y": -116.65822621938338,
      "type": "Grid",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "KkO6ji88We", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "2PiawIHWEC", "portName": "node" }] }
      },
      "inputData": { "node": {}, "debug": { "boolean": false } }
    },
    "vsFxg9ZXBl": {
      "id": "vsFxg9ZXBl",
      "x": -771.8378840266043,
      "y": 108.63713444890196,
      "type": "Button",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "IVnw3Q9XWt", "portName": "string" }] },
        "outputs": { "node": [{ "nodeId": "Efo4QHemR8", "portName": "node" }] }
      },
      "inputData": {
        "node": {},
        "size": { "size": "large" },
        "focusInset": { "boolean": false },
        "isBasic": { "boolean": false },
        "isDanger": { "boolean": false },
        "isLink": { "boolean": false },
        "isNeutral": { "boolean": false },
        "isPill": { "boolean": false },
        "isPrimary": { "boolean": false },
        "isStretched": { "boolean": true }
      }
    },
    "sytxcY_1BF": {
      "id": "sytxcY_1BF",
      "x": -732.533017447282,
      "y": -482.9773784579785,
      "type": "Checkbox",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "57z09E3B8u", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "YXdWxtUK6J", "portName": "node" }] }
      },
      "inputData": { "node": {}, "checked": { "boolean": false } }
    },
    "RbOHyNYFvx": {
      "id": "RbOHyNYFvx",
      "x": -1009.4328909209274,
      "y": -796.522290778882,
      "type": "Input",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "node": [{ "nodeId": "3EJQP2RCIh", "portName": "node-1" }] }
      },
      "inputData": { "node": {} }
    },
    "57z09E3B8u": {
      "id": "57z09E3B8u",
      "x": -1028.3202652124526,
      "y": -461.7203440761441,
      "type": "Label",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "9WB9jbH1os", "portName": "string" }] },
        "outputs": { "node": [{ "nodeId": "sytxcY_1BF", "portName": "node" }] }
      },
      "inputData": { "node": {} }
    },
    "9WB9jbH1os": {
      "id": "9WB9jbH1os",
      "x": -1269.5797078386615,
      "y": -502.2115576064284,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "string": [{ "nodeId": "57z09E3B8u", "portName": "node" }] }
      },
      "inputData": { "string": { "string": "Checkbox" } }
    },
    "CKNwSI3Z-c": {
      "id": "CKNwSI3Z-c",
      "x": -451.13987778172327,
      "y": -782.5814911813442,
      "type": "Field",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "3EJQP2RCIh", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "apqZ57T3cE", "portName": "node" }] }
      },
      "inputData": { "node": {} }
    },
    "apqZ57T3cE": {
      "id": "apqZ57T3cE",
      "x": -145.90163934426224,
      "y": -449.1803278688522,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "CKNwSI3Z-c", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "wXMY_RFw62", "portName": "node-0" }] }
      },
      "inputData": { "node": {} }
    },
    "5crBkLiz8v": {
      "id": "5crBkLiz8v",
      "x": -144.26229508196712,
      "y": -327.86885245901624,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "YXdWxtUK6J", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "wXMY_RFw62", "portName": "node-1" }] }
      },
      "inputData": { "node": {} }
    },
    "JKWHootjOc": {
      "id": "JKWHootjOc",
      "x": -1013.6363636363631,
      "y": -925.7575757575752,
      "type": "Label",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "uG3fpxnZMI", "portName": "string" }] },
        "outputs": { "node": [{ "nodeId": "3EJQP2RCIh", "portName": "node-0" }] }
      },
      "inputData": { "node": {} }
    },
    "uG3fpxnZMI": {
      "id": "uG3fpxnZMI",
      "x": -1257.5756650982476,
      "y": -974.2424242424236,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "string": [{ "nodeId": "JKWHootjOc", "portName": "node" }] }
      },
      "inputData": { "string": { "string": "Input" } }
    },
    "XnF2aGzD4_": {
      "id": "XnF2aGzD4_",
      "x": -1012.1212121212116,
      "y": -677.272681033972,
      "type": "Hint",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "KttnM1EVqj", "portName": "string" }] },
        "outputs": { "node": [{ "nodeId": "3EJQP2RCIh", "portName": "node-2" }] }
      },
      "inputData": { "node": {} }
    },
    "KttnM1EVqj": {
      "id": "KttnM1EVqj",
      "x": -1260.6059681285506,
      "y": -781.8181818181813,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": { "string": [{ "nodeId": "XnF2aGzD4_", "portName": "node" }] }
      },
      "inputData": { "string": { "string": "text input hint" } }
    },
    "YXdWxtUK6J": {
      "id": "YXdWxtUK6J",
      "x": -431.11111111111103,
      "y": -479.9999660915798,
      "type": "Field",
      "width": 200,
      "connections": {
        "inputs": { "node": [{ "nodeId": "sytxcY_1BF", "portName": "node" }] },
        "outputs": { "node": [{ "nodeId": "5crBkLiz8v", "portName": "node" }] }
      },
      "inputData": { "node": {} }
    },
    "wXMY_RFw62": {
      "id": "wXMY_RFw62",
      "x": 194.2801785290995,
      "y": -264.44436684169034,
      "type": "Fragment",
      "width": 200,
      "connections": {
        "inputs": {
          "node-0": [{ "nodeId": "apqZ57T3cE", "portName": "node" }],
          "node-1": [{ "nodeId": "5crBkLiz8v", "portName": "node" }],
          "node-2": [{ "nodeId": "-uI0E6vg5G", "portName": "node" }],
          "node-3": [{ "nodeId": "Efo4QHemR8", "portName": "node" }]
        },
        "outputs": { "node": [{ "nodeId": "KkO6ji88We", "portName": "node" }] }
      },
      "inputData": {
        "node-0": {},
        "node-1": {},
        "node-2": {},
        "node-3": {},
        "node-4": {}
      }
    },
    "3EJQP2RCIh": {
      "id": "3EJQP2RCIh",
      "x": -725,
      "y": -812.5,
      "type": "Fragment",
      "width": 200,
      "connections": {
        "inputs": {
          "node-0": [{ "nodeId": "JKWHootjOc", "portName": "node" }],
          "node-1": [{ "nodeId": "RbOHyNYFvx", "portName": "node" }],
          "node-2": [{ "nodeId": "XnF2aGzD4_", "portName": "node" }]
        },
        "outputs": { "node": [{ "nodeId": "CKNwSI3Z-c", "portName": "node" }] }
      },
      "inputData": { "node-0": {}, "node-1": {}, "node-2": {}, "node-3": {} }
    }
  }
}
