import { useMemo, useState } from 'react';

import { itemTypes, isIframe } from '../utils';

export function useEditor() {
  const [openPanel, setOpenPanel] = useState('');
  const [showOutlines, setShowOutlines] = useState(false);
  const isFrame = useMemo(() => isIframe());

  return useMemo(
    () => ({
      // settings
      settings: {
        showOutlines,
      },
      toggleOutlines() {
        setShowOutlines(!showOutlines);
      },
      // runtime
      openPanel,
      setPanel(panelName) {
        setOpenPanel(panelName || '');
      },
      // constants
      layout: {
        itemTypes,
      },
      isFrame,
    }),
    [openPanel, showOutlines, isFrame],
  );
}
