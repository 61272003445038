import React from 'react';
import { Grid } from '@zendeskgarden/react-grid';

export function Settings() {
  return (
    <Grid>
      <h1>Settings</h1>
    </Grid>
  );
}
