import { RootEngine } from 'flume';

export function createGraphEngine({
  config,
  resolvePort = (_, data) => data,
  resolveNode = (_, inputs) => inputs,
} = {}) {
  function resolvePorts(portType, data) {
    switch (portType) {
      case 'string':
        return data.string;
      case 'boolean':
        return data.boolean;
      case 'number':
        return data.number;
      default: {
        return resolvePort(portType, data);
      }
    }
  }

  // context can house library components and render calls
  function resolveNodes(node, inputValues, nodeType, context) {
    switch (node.type) {
      case 'string':
        return { string: inputValues.string, node: inputValues.string };
      case 'boolean':
        return { boolean: inputValues.boolean, node: inputValues.boolean };
      case 'number':
        return { number: inputValues.number, node: inputValues.number };
      case 'Fragment':
        return { node: Object.values(inputValues) };
      default:
        return resolveNode(node, inputValues, nodeType, context);
    }
  }

  return new RootEngine(config, resolvePorts, resolveNodes);
}
