/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import { useRef, useEffect } from 'react';
export { useId } from '@reach/auto-id';

function composeEventHandlers() {
  for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }
  return function (event) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }
    return fns.some(function (fn) {
      fn && fn.apply(void 0, [event].concat(args));
      return event && event.defaultPrevented;
    });
  };
}

var idCounter = 0;
function generateId(prefix) {
  if (prefix === void 0) {
    prefix = 'garden';
  }
  return prefix + "-" + idCounter++;
}
function setIdCounter(num) {
  idCounter = num;
}

function getControlledValue() {
  for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
    values[_key] = arguments[_key];
  }
  for (var _i = 0, _values = values; _i < _values.length; _i++) {
    var value = _values[_i];
    if (value !== undefined) {
      return value;
    }
  }
  return undefined;
}

function useCombinedRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }
  var targetRef = useRef(null);
  useEffect(function () {
    console.warn('"useCombinedRefs()" has been deprecated and will be removed in the next major version release of "@zendeskgarden/container-utilities"');
    refs.forEach(function (ref) {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, []);
  return targetRef;
}

var convertToMatrix = function convertToMatrix(array, columnCount) {
  return array.reduce(function (acc, curr) {
    if (acc.length === 0) return [[curr]];
    if (acc[acc.length - 1].length < columnCount) {
      acc[acc.length - 1].push(curr);
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);
};

var KEY_CODES = {
  ALT: 18,
  ASTERISK: 170,
  BACKSPACE: 8,
  COMMA: 188,
  DELETE: 46,
  DOWN: 40,
  END: 35,
  ENTER: 13,
  ESCAPE: 27,
  HOME: 36,
  LEFT: 37,
  NUMPAD_ADD: 107,
  NUMPAD_DECIMAL: 110,
  NUMPAD_DIVIDE: 111,
  NUMPAD_ENTER: 108,
  NUMPAD_MULTIPLY: 106,
  NUMPAD_SUBTRACT: 109,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  PERIOD: 190,
  RIGHT: 39,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
  UP: 38
};
var KEYS = {
  ALT: 'Alt',
  ASTERISK: '*',
  BACKSPACE: 'Backspace',
  COMMA: ',',
  DELETE: 'Delete',
  DOWN: 'ArrowDown',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  NUMPAD_ADD: 'Add',
  NUMPAD_DECIMAL: 'Decimal',
  NUMPAD_DIVIDE: 'Divide',
  NUMPAD_ENTER: 'Enter',
  NUMPAD_MULTIPLY: 'Multiply',
  NUMPAD_SUBTRACT: 'Subtract',
  PAGE_DOWN: 'PageDown',
  PAGE_UP: 'PageUp',
  PERIOD: '.',
  RIGHT: 'ArrowRight',
  SHIFT: 'Shift',
  SPACE: ' ',
  TAB: 'Tab',
  UNIDENTIFIED: 'Unidentified',
  UP: 'ArrowUp'
};

var DocumentPosition;
(function (DocumentPosition) {
  DocumentPosition[DocumentPosition["DISCONNECTED"] = 1] = "DISCONNECTED";
  DocumentPosition[DocumentPosition["PRECEDING"] = 2] = "PRECEDING";
  DocumentPosition[DocumentPosition["FOLLOWING"] = 4] = "FOLLOWING";
  DocumentPosition[DocumentPosition["CONTAINS"] = 8] = "CONTAINS";
  DocumentPosition[DocumentPosition["CONTAINED_BY"] = 16] = "CONTAINED_BY";
  DocumentPosition[DocumentPosition["IMPLEMENTATION_SPECIFIC"] = 32] = "IMPLEMENTATION_SPECIFIC";
})(DocumentPosition || (DocumentPosition = {}));

var ContainerOrientation;
(function (ContainerOrientation) {
  ContainerOrientation["HORIZONTAL"] = "horizontal";
  ContainerOrientation["VERTICAL"] = "vertical";
})(ContainerOrientation || (ContainerOrientation = {}));

export { ContainerOrientation, DocumentPosition, KEYS, KEY_CODES, composeEventHandlers, convertToMatrix, generateId, getControlledValue, setIdCounter, useCombinedRefs };
