import React from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { XXL } from '@zendeskgarden/react-typography';

import { useEditorContext } from '../contexts';

export function GraphsChart() {
  const {
    graph: { updateNodes },
    graphs,
  } = useEditorContext();

  return (
    <>
      <Row
        justifyContent="center"
        style={{ marginTop: '24px', marginBottom: '24px' }}
      >
        <XXL>Graphs</XXL>
      </Row>

      {React.Children.toArray(
        // eslint-disable-next-line react/jsx-key
        graphs
          .reduce((acc, graph, index) => {
            const mod = index % 4;

            if (mod === 0) {
              acc.push([]);
            }

            acc[acc.length - 1].push(graph);

            return acc;
          }, [])
          .map((group) => (
            // eslint-disable-next-line react/jsx-key
            <Row style={{ padding: '12px' }}>
              {React.Children.toArray(
                group.map((graph) => (
                  // eslint-disable-next-line react/jsx-key
                  <Col sm={6} md={4} lg={3}>
                    <Button isStretched onClick={() => updateNodes(graph.graph)}>
                      {graph.name}
                    </Button>
                  </Col>
                )),
              )}
            </Row>
          )),
      )}
    </>
  );
}
