import { FlumeConfig, Colors, Controls } from 'flume';

const _ports = [
  {
    type: 'string',
    name: 'string',
    label: 'Text',
    color: Colors.green,
    controls: [
      Controls.text({
        name: 'string',
        label: 'Text',
      }),
    ],
  },
  {
    type: 'number',
    name: 'number',
    label: 'Number',
    color: Colors.blue,
    controls: [
      Controls.number({
        name: 'number',
        label: 'Number',
      }),
    ],
  },
  {
    type: 'boolean',
    name: 'boolean',
    label: 'Boolean',
    color: Colors.orange,
    controls: [
      Controls.checkbox({
        name: 'boolean',
        label: 'Boolean',
        defaultValue: false,
      }),
    ],
  },
  {
    type: 'node',
    name: 'node',
    label: 'Node',
    acceptTypes: ['string', 'node'],
    color: Colors.red,
  },
  {
    type: 'graph',
    name: 'graph',
    label: 'Graph',
    color: Colors.purple,
    controls: [
      Controls.custom({
        name: 'graph',
        label: 'Select Graph',
        render: (data, onChange, context, ...args) =>
          context.controls.GraphSelect(data, onChange, context, ...args),
      }),
    ],
  },
];

const _nodes = [
  {
    type: 'string',
    label: 'Text',
    description: 'Outputs a string of text',
    inputs: (ports) => [ports.string()],
    outputs: (ports) => [ports.string(), ports.node()],
  },
  {
    type: 'boolean',
    label: 'Boolean',
    description: 'Outputs a boolean',
    inputs: (ports) => [ports.boolean()],
    outputs: (ports) => [ports.boolean(), ports.node()],
  },
  {
    type: 'number',
    label: 'Number',
    description: 'Outputs a number',
    inputs: (ports) => [ports.number()],
    outputs: (ports) => [ports.number(), ports.node()],
  },
  {
    type: 'Fragment',
    label: 'Fragment',
    description: 'Combines multiple children',
    // when maximum number of node inputs is used, allows an additional node to be appended dynamically
    inputs: (ports) => (_, connections) => {
      return [...Object.values(connections.inputs), 0].map((_, index) => {
        return ports.node({ name: `node-${index}`, label: 'Node' });
      });
    },
    outputs: (ports) => [ports.node()],
  },
  {
    type: 'Graph',
    label: 'Graph',
    description: 'Independent Graph',
    // use context to select a graph and generate the inputs. Should output node
    inputs: (ports) => [ports.graph()],
    outputs: (ports) => [ports.node()],
  },
];

const rootNodes = {
  // TODO: add Data graph & Logic graph root nodes
  // UI graph
  view: {
    type: 'view',
    label: 'View',
    inputs: (ports) => [
      ports.node({
        name: 'node',
        label: 'Node',
      }),
      ports.string({
        name: 'title',
        label: 'Title',
      }),
      ports.string({
        name: 'description',
        label: 'Description',
      }),
    ],
  },
};

export function createGraphConfig({
  nodes = [],
  ports = [],
  rootNode = rootNodes.view,
} = {}) {
  const config = new FlumeConfig();
  return []
    .concat(nodes, _nodes)
    .reduce(
      (_config, port) => _config.addNodeType(port),
      []
        .concat(ports, _ports)
        .reduce((_config, node) => _config.addPortType(node), config),
    )
    .addRootNodeType(rootNode);
}
