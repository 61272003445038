import React from 'react';
import { render } from 'react-dom';

import '@zendeskgarden/css-bedrock';

import './styles.css';

import App from './App';

render(React.createElement(App), document.getElementById('root'));
