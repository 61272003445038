import React from 'react';

import { Editor } from './editor';
import {
  ports,
  nodes,
  components,
  resolveNode,
  resolvePort,
  // setDefaultGraphNode,
  // clearDefaultGraphNode,
} from './config';

import meta from './meta/components.meta.json';

// mutate localstorage on load, before first render
// clearDefaultGraphNode();
// setDefaultGraphNode();

// converging point of where consumer configures/renders editor
export default function App() {
  return (
    <Editor
      meta={meta}
      components={components}
      graph={{
        ports,
        nodes,
        resolveNode,
        resolvePort,
      }}
    />
  );
}
