import React, { useCallback } from 'react';
import {
  Header,
  HeaderItem,
  HeaderItemIcon,
  HeaderItemText,
} from '@zendeskgarden/react-chrome';

import {
  MenuTrayIcon,
  // BookIcon,
  // MonitorIcon,
  AdjustIcon,
} from './Icons';
import { useEditorContext } from './contexts';

export function HeaderBar() {
  const {
    editor: { openPanel, setPanel },
  } = useEditorContext();

  const togglePanel = useCallback(
    (panel) => () => {
      if (panel === openPanel) setPanel();
      else setPanel(panel);
    },
    [openPanel],
  );

  return (
    <Header>
      <HeaderItem onClick={togglePanel('attributes')}>
        <HeaderItemIcon>
          <AdjustIcon />
        </HeaderItemIcon>
        <HeaderItemText isClipped>Attributes</HeaderItemText>
      </HeaderItem>
      {/* <HeaderItem onClick={togglePanel('display')}>
        <HeaderItemIcon>
          <MonitorIcon />
        </HeaderItemIcon>
        <HeaderItemText isClipped>Screen Sizes</HeaderItemText>
      </HeaderItem> */}
      <HeaderItem onClick={togglePanel('catalog')}>
        <HeaderItemIcon>
          <MenuTrayIcon />
        </HeaderItemIcon>
        <HeaderItemText isClipped>Catalog</HeaderItemText>
      </HeaderItem>
      {/* <HeaderItem onClick={togglePanel('documentation')}>
        <HeaderItemIcon>
          <BookIcon />
        </HeaderItemIcon>
        <HeaderItemText isClipped>Documentation</HeaderItemText>
      </HeaderItem> */}
    </Header>
  );
}
