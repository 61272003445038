import { useEffect } from 'react';

import { IFRAME_PATH } from '../../utils';
import { useEditorContext } from '../contexts';

export function GraphRenderer() {
  const {
    graph: { renderRoot, updateNodes },
  } = useEditorContext();

  useEffect(() => {
    if (window.location.pathname.endsWith(IFRAME_PATH)) {
      window.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'update') {
          updateNodes(event.data.nodes);
        }
      });
    }
  }, []);

  return renderRoot();
}
