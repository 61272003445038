import React from 'react';

import { LayoutCatalogPanel, LayoutPropertiesPanel } from './layouts';
import { useEditorContext } from './contexts';

export function Panels() {
  const {
    editor: { openPanel, setPanel },
  } = useEditorContext();
  // set ID prop as anchor link if open
  return (
    <>
      <LayoutCatalogPanel
        isOpen={openPanel === 'catalog'}
        onClose={() => setPanel()}
      />
      <LayoutPropertiesPanel
        isOpen={openPanel === 'attributes'}
        onClose={() => setPanel()}
      />
    </>
  );
}
