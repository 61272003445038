import { IFRAME_PATH, NODES_KEY } from './constants';

export function isIframe() {
  return location.pathname.endsWith(IFRAME_PATH);
}

export function setNodesToStorage(nodes) {
  localStorage.setItem(NODES_KEY, JSON.stringify(nodes));
}

export function getNodesFromStorage() {
  return JSON.parse(localStorage.getItem(NODES_KEY) || '{}');
}
