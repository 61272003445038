/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useState } from 'react';
import { useUIDSeed } from 'react-uid';
import { getControlledValue, composeEventHandlers, KEY_CODES } from '@zendeskgarden/container-utilities';
import PropTypes from 'prop-types';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded$1 = ["role", "ariaLevel"],
    _excluded2 = ["index", "role", "tabIndex"],
    _excluded3 = ["index", "role"];
function useAccordion(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      idPrefix = _ref.idPrefix,
      expandedSections = _ref.expandedSections,
      onChange = _ref.onChange,
      _ref$expandable = _ref.expandable,
      expandable = _ref$expandable === void 0 ? true : _ref$expandable,
      _ref$collapsible = _ref.collapsible,
      collapsible = _ref$collapsible === void 0 ? true : _ref$collapsible,
      defaultExpandedSections = _ref.defaultExpandedSections;
  var isControlled = expandedSections !== null && expandedSections !== undefined;
  var seed = useUIDSeed();
  var _useState = useState(idPrefix || seed("accordion_" + '1.1.1')),
      prefix = _useState[0];
  var TRIGGER_ID = prefix + "--trigger";
  var PANEL_ID = prefix + "--panel";
  var _useState2 = useState(defaultExpandedSections || [0]),
      expandedState = _useState2[0],
      setExpandedState = _useState2[1];
  var controlledExpandedState = getControlledValue(expandedSections, expandedState);
  var _useState3 = useState(collapsible ? [] : expandedState),
      disabledState = _useState3[0],
      setDisabledState = _useState3[1];
  var sectionIndices = [];
  var toggle = function toggle(index) {
    var expanded = [];
    var disabled = [];
    sectionIndices.forEach(function (sectionIndex) {
      var isExpanded = false;
      if (sectionIndex === index) {
        isExpanded = collapsible ? expandedState.indexOf(sectionIndex) === -1 : true;
      } else if (expandable) {
        isExpanded = expandedState.indexOf(sectionIndex) !== -1;
      }
      if (isExpanded) {
        expanded.push(sectionIndex);
        if (!collapsible) {
          disabled.push(sectionIndex);
        }
      }
    });
    if (onChange) {
      onChange(index);
    }
    if (isControlled === false) {
      setExpandedState(expanded);
    }
    setDisabledState(disabled);
  };
  var getHeaderProps = function getHeaderProps(_temp2) {
    var _ref2 = _temp2 === void 0 ? {} : _temp2,
        _ref2$role = _ref2.role,
        role = _ref2$role === void 0 ? 'heading' : _ref2$role,
        ariaLevel = _ref2.ariaLevel,
        props = _objectWithoutPropertiesLoose(_ref2, _excluded$1);
    if (ariaLevel === undefined) {
      throw new Error('Accessibility Error: You must apply the `ariaLevel` prop to the element that contains your heading.');
    }
    return _extends({
      role: role,
      'aria-level': ariaLevel,
      'data-garden-container-id': 'containers.accordion',
      'data-garden-container-version': '1.1.1'
    }, props);
  };
  var getTriggerProps = function getTriggerProps(_temp3) {
    var _ref3 = _temp3 === void 0 ? {} : _temp3,
        index = _ref3.index,
        _ref3$role = _ref3.role,
        role = _ref3$role === void 0 ? 'button' : _ref3$role,
        _ref3$tabIndex = _ref3.tabIndex,
        tabIndex = _ref3$tabIndex === void 0 ? 0 : _ref3$tabIndex,
        props = _objectWithoutPropertiesLoose(_ref3, _excluded2);
    if (index === undefined) {
      throw new Error('Accessibility Error: You must provide an `index` option to `getTriggerProps()`');
    }
    sectionIndices.push(index);
    return _extends({
      id: TRIGGER_ID + ":" + index,
      role: role,
      tabIndex: tabIndex,
      'aria-controls': PANEL_ID + ":" + index,
      'aria-disabled': disabledState.indexOf(index) !== -1,
      'aria-expanded': isControlled ? controlledExpandedState.includes(index) : expandedState.includes(index),
      onClick: composeEventHandlers(props.onClick, function () {
        return toggle(index);
      }),
      onKeyDown: composeEventHandlers(props.onKeyDown, function (event) {
        if (event.keyCode === KEY_CODES.SPACE || event.keyCode === KEY_CODES.ENTER) {
          toggle(index);
          event.preventDefault();
        }
      })
    }, props);
  };
  var getPanelProps = function getPanelProps(_temp4) {
    var _ref4 = _temp4 === void 0 ? {} : _temp4,
        index = _ref4.index,
        _ref4$role = _ref4.role,
        role = _ref4$role === void 0 ? 'region' : _ref4$role,
        props = _objectWithoutPropertiesLoose(_ref4, _excluded3);
    if (index === undefined) {
      throw new Error('Accessibility Error: You must provide an `index` option to `getSectionProps()`');
    }
    return _extends({
      id: PANEL_ID + ":" + index,
      role: role,
      'aria-hidden': isControlled ? !controlledExpandedState.includes(index) : !expandedState.includes(index),
      'aria-labelledby': TRIGGER_ID + ":" + index
    }, props);
  };
  return {
    getHeaderProps: getHeaderProps,
    getTriggerProps: getTriggerProps,
    getPanelProps: getPanelProps,
    expandedSections: controlledExpandedState,
    disabledSections: disabledState
  };
}

var _excluded = ["children", "render"];
var AccordionContainer = function AccordionContainer(props) {
  var children = props.children,
      _props$render = props.render,
      render = _props$render === void 0 ? children : _props$render,
      options = _objectWithoutPropertiesLoose(props, _excluded);
  return React.createElement(React.Fragment, null, render(useAccordion(options)));
};
AccordionContainer.defaultProps = {
  expandable: true,
  collapsible: true
};
AccordionContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  expandedSections: PropTypes.array,
  expandable: PropTypes.bool,
  collapsible: PropTypes.bool,
  idPrefix: PropTypes.string
};

export { AccordionContainer, useAccordion };
