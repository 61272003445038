import React, { Children, useState } from 'react';
import { Sheet } from '@zendeskgarden/react-chrome';
import { Accordion } from '@zendeskgarden/react-accordions';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import { Well, Title } from '@zendeskgarden/react-notifications';

import { LayoutContainer } from './LayoutContainer';
import { useEditorContext } from '../contexts';

// eslint-disable-next-line react/prop-types
export function LayoutPropertiesPanel({ isOpen, onClose }) {
  // TODO: add footer to apply changes or cancel them (when changed)
  return (
    <Sheet isOpen={isOpen}>
      <Sheet.Header>
        <Sheet.Title>Properties</Sheet.Title>
      </Sheet.Header>
      <Sheet.Body style={{ padding: 0 }}>
        <Accordion level={4}>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>Info</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>
              Details on selected item, warnings and other info
            </Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>Properties</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>Control the props</Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>Theming</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>Customize Theme in Subtree</Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>CSS</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>Add styles</Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>Events</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>
              Configure event listeners and how they are handled
            </Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Accordion.Label>Actions</Accordion.Label>
            </Accordion.Header>
            <Accordion.Panel>Create variant (fork)</Accordion.Panel>
          </Accordion.Section>
        </Accordion>
      </Sheet.Body>
      <Sheet.Close onClick={onClose} />
    </Sheet>
  );
}

// eslint-disable-next-line react/prop-types
export function LayoutCatalogPanel({ isOpen, onClose }) {
  const { graphs } = useEditorContext();

  const [selectedTab, setSelectedTab] = useState('components');

  return (
    <Sheet isOpen={isOpen}>
      <Sheet.Header>
        <Sheet.Title>Catalog</Sheet.Title>
      </Sheet.Header>
      <Sheet.Body style={{ padding: 0 }}>
        <div>
          <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
            <TabList>
              <Tab item="components">Components</Tab>
              <Tab item="graphs">Graphs</Tab>
            </TabList>
            <TabPanel item="components" style={{ padding: '20px' }}>
              {Children.toArray(
                [
                  {
                    type: 'Anchor',
                  },
                  {
                    type: 'Button',
                  },
                  {
                    type: 'Row',
                  },
                  {
                    type: 'Col',
                  },
                ].map((node) => (
                  // eslint-disable-next-line react/jsx-key
                  <div style={{ marginBottom: '20px' }}>
                    <LayoutContainer node={node}>
                      <Well isRecessed>
                        <Title>{node.type}</Title>
                        {node.description}
                      </Well>
                    </LayoutContainer>
                  </div>
                )),
              )}
            </TabPanel>
            <TabPanel item="graphs" style={{ padding: '20px' }}>
              {Children.toArray(
                graphs.map((graph) => (
                  // eslint-disable-next-line react/jsx-key
                  <div style={{ marginBottom: '20px' }}>
                    <LayoutContainer
                      node={{
                        type: 'Graph',
                        label: graph.name,
                        inputData: { graph: { graph } },
                        ...graph,
                      }}
                    >
                      <Well isRecessed>
                        <Title>{graph.name}</Title>
                        {graph.description}
                      </Well>
                    </LayoutContainer>
                  </div>
                )),
              )}
              {/* TODO: add graphs to work bench action button */}
            </TabPanel>
          </Tabs>
        </div>
      </Sheet.Body>
      <Sheet.Close onClick={onClose} />
    </Sheet>
  );
}
