import { useMemo } from 'react';
import { nanoid } from 'nanoid';
// import {} from 'immer';
import { detailedDiff } from 'deep-object-diff';

import { traverseFlumeNodes } from '../utils';
import { useGraphNodes } from './useGraphNodes';

function createNode({
  type,
  inputData,
  connections,
  inputs,
  outputs,
  ...rest
} = {}) {
  const node = {
    ...rest,
    id: nanoid(10),
    width: 200,
    type,
    inputData: inputData || {},
    connections: connections || {
      inputs: inputs || {},
      outputs: outputs || {},
    },
  };

  return node;
}

function connectNodes(node, parent, nodes) {
  const {
    connections: { inputs },
  } = parent;

  const updatedNodes = [];

  if (inputs.node && inputs.node.length === 1) {
    const connectedNode = nodes[inputs.node[0].nodeId];
    if (connectedNode.type === 'Fragment') {
      // add to fragment
      const currentNodePortName = `node-${
        Object.keys(connectedNode.connections.inputs).length
      }`;
      connectedNode.connections.inputs[currentNodePortName] = [
        { nodeId: node.id, portName: 'node' },
      ];
      node.connections.outputs.node = [
        { nodeId: connectedNode.id, portName: currentNodePortName },
      ];
      parent.connections.inputs.node = [
        { nodeId: connectedNode.id, portName: 'node' },
      ];

      updatedNodes.push(connectedNode, parent, node);
    } else {
      // get the currently connected input from parent node
      const [{ nodeId: currentConnectionId }] = parent.connections.inputs.node;
      const connectedNode = nodes[currentConnectionId];
      // create a new fragment for multiple inputs (new + pre-existing connection)
      const fragmentNode = createNode({ type: 'Fragment' });
      // connect the newly connected node and pre-existing one using named node ports
      fragmentNode.connections.inputs['node-0'] = [
        { nodeId: connectedNode.id, portName: 'node' },
      ];
      fragmentNode.connections.inputs['node-1'] = [
        { nodeId: node.id, portName: 'node' },
      ];
      // connect the new children to the fragment named node ports
      connectedNode.connections.outputs['node'] = [
        { nodeId: fragmentNode.id, portName: 'node-0' },
      ];
      node.connections.outputs['node'] = [
        { nodeId: fragmentNode.id, portName: 'node-1' },
      ];
      // connect the fragment to the parent element
      fragmentNode.connections.outputs.node = [
        { nodeId: parent.id, portName: 'node' },
      ];
      // connect the parent node to the new fragment
      parent.connections.inputs.node = [
        { nodeId: fragmentNode.id, portName: 'node' },
      ];

      updatedNodes.push(fragmentNode, connectedNode, parent, node);
    }
  } else {
    // simplest connection child to parent
    node.connections.outputs.node = [{ nodeId: parent.id, portName: 'node' }];
    parent.connections.inputs.node = [{ nodeId: node.id, portName: 'node' }];

    updatedNodes.push(parent, node);
  }

  return {
    ...nodes,
    ...updatedNodes.reduce((map, current) => {
      map[current.id] = current;
      return map;
    }, {}),
  };
}

export function useTree(graphConfig, context) {
  const graph = useGraphNodes(graphConfig, context);
  const tree = useMemo(
    () => traverseFlumeNodes(graph.nodes, undefined, context),
    [graph.nodes, context],
  );

  return useMemo(
    () => ({
      graph,
      tree,
      addNode(node, parent) {
        if (node.id in graph.nodes === false) {
          graph.updateNodes(
            connectNodes(createNode(node), parent, graph.nodes),
            false,
          );
        }
      },
      updateNodes(incomingNodes) {
        console.log(detailedDiff(graph.nodes, incomingNodes));
        graph.setNodes(incomingNodes);
      },
    }),
    [graph, tree],
  );
}
