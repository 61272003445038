{
  "name": "Simple Buttons Graph",
  "description": "testing graph 2",
  "extensions": [],
  "graph": {
    "qEUOPqxEkt": {
      "x": 0,
      "y": 0,
      "type": "view",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "b2olrHHcF6",
              "portName": "node"
            }
          ]
        },
        "outputs": {}
      },
      "inputData": {
        "node": {},
        "title": {
          "string": ""
        },
        "description": {
          "string": ""
        }
      },
      "root": true,
      "id": "qEUOPqxEkt"
    },
    "b2olrHHcF6": {
      "id": "b2olrHHcF6",
      "x": -563.1067961165048,
      "y": -120.3883495145631,
      "type": "Row",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "d5B6ujSTM6",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "qEUOPqxEkt",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "node": {}
      }
    },
    "d5B6ujSTM6": {
      "id": "d5B6ujSTM6",
      "x": -864,
      "y": -172,
      "type": "Fragment",
      "width": 200,
      "connections": {
        "inputs": {
          "node-0": [
            {
              "nodeId": "F-bv1T7TVz",
              "portName": "node"
            }
          ],
          "node-1": [
            {
              "nodeId": "T7dtgqwM-h",
              "portName": "node"
            }
          ],
          "node-2": [
            {
              "nodeId": "L3tZOlA-Jt",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "b2olrHHcF6",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "node-0": {}
      }
    },
    "L3tZOlA-Jt": {
      "id": "L3tZOlA-Jt",
      "x": -1278,
      "y": -318,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "29BG7nIl5A",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "d5B6ujSTM6",
              "portName": "node-2"
            }
          ]
        }
      },
      "inputData": {
        "node": {}
      }
    },
    "T7dtgqwM-h": {
      "id": "T7dtgqwM-h",
      "x": -1226,
      "y": -62,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "licIPT5BJL",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "d5B6ujSTM6",
              "portName": "node-1"
            }
          ]
        }
      },
      "inputData": {
        "node": {}
      }
    },
    "F-bv1T7TVz": {
      "id": "F-bv1T7TVz",
      "x": -1152,
      "y": 152,
      "type": "Col",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "3MKJiQVrzM",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "d5B6ujSTM6",
              "portName": "node-0"
            }
          ]
        }
      },
      "inputData": {
        "node": {}
      }
    },
    "29BG7nIl5A": {
      "id": "29BG7nIl5A",
      "x": -1562,
      "y": -532,
      "type": "Button",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "w9l0zo6wAW",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "L3tZOlA-Jt",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "node": {},
        "size": {
          "size": "medium"
        },
        "focusInset": {
          "boolean": false
        },
        "isBasic": {
          "boolean": false
        },
        "isDanger": {
          "boolean": false
        },
        "isLink": {
          "boolean": false
        },
        "isNeutral": {
          "boolean": false
        },
        "isPill": {
          "boolean": false
        },
        "isPrimary": {
          "boolean": false
        },
        "isStretched": {
          "boolean": true
        }
      }
    },
    "w9l0zo6wAW": {
      "id": "w9l0zo6wAW",
      "x": -1824,
      "y": -520,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": {
          "node": [
            {
              "nodeId": "29BG7nIl5A",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "string": {
          "string": "Button 3"
        }
      }
    },
    "licIPT5BJL": {
      "id": "licIPT5BJL",
      "x": -1802,
      "y": -238,
      "type": "Button",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "RRkBbjqBQl",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "T7dtgqwM-h",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "node": {},
        "size": {
          "size": "medium"
        },
        "focusInset": {
          "boolean": false
        },
        "isBasic": {
          "boolean": false
        },
        "isDanger": {
          "boolean": false
        },
        "isLink": {
          "boolean": false
        },
        "isNeutral": {
          "boolean": false
        },
        "isPill": {
          "boolean": false
        },
        "isPrimary": {
          "boolean": false
        },
        "isStretched": {
          "boolean": true
        }
      }
    },
    "3MKJiQVrzM": {
      "id": "3MKJiQVrzM",
      "x": -1526,
      "y": 128,
      "type": "Button",
      "width": 200,
      "connections": {
        "inputs": {
          "node": [
            {
              "nodeId": "kDKpBhfknF",
              "portName": "node"
            }
          ]
        },
        "outputs": {
          "node": [
            {
              "nodeId": "F-bv1T7TVz",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "node": {},
        "size": {
          "size": "medium"
        },
        "focusInset": {
          "boolean": false
        },
        "isBasic": {
          "boolean": false
        },
        "isDanger": {
          "boolean": false
        },
        "isLink": {
          "boolean": false
        },
        "isNeutral": {
          "boolean": false
        },
        "isPill": {
          "boolean": false
        },
        "isPrimary": {
          "boolean": false
        },
        "isStretched": {
          "boolean": true
        }
      }
    },
    "kDKpBhfknF": {
      "id": "kDKpBhfknF",
      "x": -1830,
      "y": 310,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": {
          "node": [
            {
              "nodeId": "3MKJiQVrzM",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "string": {
          "string": "Button 1"
        }
      }
    },
    "RRkBbjqBQl": {
      "id": "RRkBbjqBQl",
      "x": -2118,
      "y": -208,
      "type": "string",
      "width": 200,
      "connections": {
        "inputs": {},
        "outputs": {
          "node": [
            {
              "nodeId": "licIPT5BJL",
              "portName": "node"
            }
          ]
        }
      },
      "inputData": {
        "string": {
          "string": "Button 2"
        }
      }
    }
  }
}
