import React, { useMemo } from 'react';
import { Router, LocationProvider } from '@reach/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Chrome, Body, Content, Main, SkipNav } from '@zendeskgarden/react-chrome';

import { isIframe } from '../utils';
import { useEditor, useExtensions, useGraphs, useTree } from '../hooks';

import { LayoutEditor } from './layouts';
import { GraphEditor, GraphPreview, GraphRenderer } from './graph';
import { CodeEditor } from './code';
import { ExtensionsEditor } from './extensions';
import { Catalog, ComponentsCollection } from './catalog';
import { Settings } from './settings';

import {
  DashboardIcon,
  AppIcon,
  WorkflowIcon,
  SettingsIcon,
  WidgetIcon,
  PlatformIcon,
} from './Icons';
import { NavBar, NavLink } from './Navigation';
import { Panels } from './Panels';
import { HeaderBar } from './Header';
import { EditorContext } from './contexts';
import * as controls from './controls';

function GraphEditorView() {
  return (
    <>
      <GraphEditor />
      <GraphPreview />
    </>
  );
}

export function Editor({
  /* eslint-disable react/prop-types */
  graph: graphConfig,
  components,
  meta,
  /* eslint-enable react/prop-types */
}) {
  const extensions = useExtensions();
  const graphs = useGraphs();
  const context = useMemo(
    () => ({ components, graphs, extensions, controls }),
    [components, graphs, extensions],
  );
  const tree = useTree(graphConfig, context);
  const editor = useEditor();

  return (
    <EditorContext.Provider
      value={useMemo(
        () => ({
          ...tree,
          editor,
          extensions,
          graphs,
          meta,
          context,
        }),
        [tree, editor, extensions, graphs, meta, context],
      )}
    >
      {/* TODO: code split here based on rendering path */}
      {isIframe() ? (
        <GraphRenderer />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider>
            <LocationProvider>
              <Chrome isFluid>
                <SkipNav targetId="main-content">Skip to main content</SkipNav>
                <NavBar>
                  <NavLink to="graphs/app" label="Catalog">
                    <AppIcon />
                  </NavLink>
                  <NavLink to="graphs/app/extensions" label="Extensions Editor">
                    <PlatformIcon />
                  </NavLink>
                  <NavLink to="graphs/app/layout" label="Layout Editor">
                    <DashboardIcon />
                  </NavLink>
                  <NavLink to="graphs/app/graph" label="Graph Editor">
                    <WorkflowIcon />
                  </NavLink>
                  <NavLink to="graphs/app/code" label="Code Editor">
                    <WidgetIcon />
                  </NavLink>
                  <NavLink to="graphs/app/settings" label="Settings">
                    <SettingsIcon />
                  </NavLink>
                </NavBar>
                <Body>
                  <HeaderBar />
                  <Content id="main-content">
                    <Main>
                      <Router>
                        <Catalog path="graphs" />
                        <Catalog path="graphs/app" />
                        <LayoutEditor path="graphs/app/layout" />
                        <GraphEditorView path="graphs/app/graph" />
                        <CodeEditor path="graphs/app/code" />
                        <ExtensionsEditor path="graphs/app/extensions" />
                        <Settings path="graphs/app/settings" />
                        <ComponentsCollection path="graphs/app/components" />
                      </Router>
                    </Main>
                    <Panels />
                  </Content>
                </Body>
              </Chrome>
            </LocationProvider>
          </ThemeProvider>
        </DndProvider>
      )}
    </EditorContext.Provider>
  );
}
