/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider as ThemeProvider$1, withTheme as withTheme$1, css, keyframes } from 'styled-components';
import { useFocusVisible } from '@zendeskgarden/container-focusvisible';
import { getControlledValue } from '@zendeskgarden/container-utilities';
import { rgba, darken, lighten, getValueAndUnit, math } from 'polished';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

var PALETTE = {
  black: '#000',
  white: '#fff',
  product: {
    support: '#00a656',
    message: '#37b8af',
    explore: '#30aabc',
    gather: '#f6c8be',
    guide: '#ff6224',
    connect: '#ff6224',
    chat: '#f79a3e',
    talk: '#efc93d',
    sell: '#c38f00'
  },
  grey: {
    100: '#f8f9f9',
    200: '#e9ebed',
    300: '#d8dcde',
    400: '#c2c8cc',
    500: '#87929d',
    600: '#68737d',
    700: '#49545c',
    800: '#2f3941'
  },
  blue: {
    100: '#edf7ff',
    200: '#cee2f2',
    300: '#adcce4',
    400: '#5293c7',
    500: '#337fbd',
    600: '#1f73b7',
    700: '#144a75',
    800: '#0f3554'
  },
  red: {
    100: '#fff0f1',
    200: '#f5d5d8',
    300: '#f5b5ba',
    400: '#e35b66',
    500: '#d93f4c',
    600: '#cc3340',
    700: '#8c232c',
    800: '#681219'
  },
  yellow: {
    100: '#fff7ed',
    200: '#ffeedb',
    300: '#fed6a8',
    400: '#ffb057',
    500: '#f79a3e',
    600: '#ed8f1c',
    700: '#ad5918',
    800: '#703815'
  },
  green: {
    100: '#edf8f4',
    200: '#d1e8df',
    300: '#aecfc2',
    400: '#5eae91',
    500: '#228f67',
    600: '#038153',
    700: '#186146',
    800: '#0b3b29'
  },
  kale: {
    100: '#f5fcfc',
    200: '#daeded',
    300: '#bdd9d7',
    400: '#90bbbb',
    500: '#467b7c',
    600: '#17494d',
    700: '#03363d',
    800: '#012b30'
  },
  fuschia: {
    400: '#d653c2',
    600: '#a81897',
    M400: '#cf62a8',
    M600: '#a8458c'
  },
  pink: {
    400: '#ec4d63',
    600: '#d42054',
    M400: '#d57287',
    M600: '#b23a5d'
  },
  crimson: {
    400: '#e34f32',
    600: '#c72a1c',
    M400: '#cc6c5b',
    M600: '#b24a3c'
  },
  orange: {
    400: '#de701d',
    600: '#bf5000',
    M400: '#d4772c',
    M600: '#b35827'
  },
  lemon: {
    400: '#ffd424',
    600: '#ffbb10',
    M400: '#e7a500',
    M600: '#c38f00'
  },
  lime: {
    400: '#43b324',
    600: '#2e8200',
    M400: '#519e2d',
    M600: '#47782c'
  },
  mint: {
    400: '#00a656',
    600: '#058541',
    M400: '#299c66',
    M600: '#2e8057'
  },
  teal: {
    400: '#02a191',
    600: '#028079',
    M400: '#2d9e8f',
    M600: '#3c7873'
  },
  azure: {
    400: '#3091ec',
    600: '#1371d6',
    M400: '#5f8dcf',
    M600: '#3a70b2'
  },
  royal: {
    400: '#5d7df5',
    600: '#3353e2',
    M400: '#7986d8',
    M600: '#4b61c3'
  },
  purple: {
    400: '#b552e2',
    600: '#6a27b8',
    M400: '#b072cc',
    M600: '#9358b0'
  }
};

var BASE = 4;
var borderRadii = {
  sm: "".concat(BASE / 2, "px"),
  md: "".concat(BASE, "px")
};
var borderStyles = {
  solid: 'solid'
};
var borderWidths = {
  sm: '1px',
  md: '3px'
};
var borders = {
  sm: "".concat(borderWidths.sm, " ").concat(borderStyles.solid),
  md: "".concat(borderWidths.md, " ").concat(borderStyles.solid)
};
var breakpoints = {
  xs: '0px',
  sm: "".concat(BASE * 144, "px"),
  md: "".concat(BASE * 192, "px"),
  lg: "".concat(BASE * 248, "px"),
  xl: "".concat(BASE * 300, "px")
};
var colors = {
  background: PALETTE.white,
  foreground: PALETTE.grey[800],
  primaryHue: 'blue',
  dangerHue: 'red',
  warningHue: 'yellow',
  successHue: 'green',
  neutralHue: 'grey',
  chromeHue: 'kale'
};
var fonts = {
  mono: ['SFMono-Regular'
  , 'Consolas'
  , '"Liberation Mono"'
  , 'Menlo', 'Courier', 'monospace'].join(','),
  system: ['system-ui'
  , '-apple-system'
  , 'BlinkMacSystemFont'
  , '"Segoe UI"'
  , 'Roboto'
  , 'Oxygen-Sans'
  , 'Ubuntu'
  , 'Cantarell'
  , '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
};
var fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '18px',
  xl: '22px',
  xxl: '26px',
  xxxl: '36px'
};
var fontWeights = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900
};
var iconSizes = {
  sm: '12px',
  md: '16px',
  lg: '26px'
};
var lineHeights = {
  sm: "".concat(BASE * 4, "px"),
  md: "".concat(BASE * 5, "px"),
  lg: "".concat(BASE * 6, "px"),
  xl: "".concat(BASE * 7, "px"),
  xxl: "".concat(BASE * 8, "px"),
  xxxl: "".concat(BASE * 11, "px")
};
var palette = _objectSpread2({}, PALETTE);
delete palette.product;
var shadowWidths = {
  sm: '2px',
  md: '3px'
};
var shadows = {
  sm: function sm(color) {
    return "0 0 0 ".concat(shadowWidths.sm, " ").concat(color);
  },
  md: function md(color) {
    return "0 0 0 ".concat(shadowWidths.md, " ").concat(color);
  },
  lg: function lg(offsetY, blurRadius, color) {
    return "0 ".concat(offsetY, " ").concat(blurRadius, " 0 ").concat(color);
  }
};
var space = {
  base: BASE,
  xxs: "".concat(BASE, "px"),
  xs: "".concat(BASE * 2, "px"),
  sm: "".concat(BASE * 3, "px"),
  md: "".concat(BASE * 5, "px"),
  lg: "".concat(BASE * 8, "px"),
  xl: "".concat(BASE * 10, "px"),
  xxl: "".concat(BASE * 12, "px")
};
var DEFAULT_THEME = {
  borders: borders,
  borderRadii: borderRadii,
  borderStyles: borderStyles,
  borderWidths: borderWidths,
  breakpoints: breakpoints,
  colors: _objectSpread2({
    base: 'light'
  }, colors),
  components: {},
  fonts: fonts,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  iconSizes: iconSizes,
  lineHeights: lineHeights,
  palette: palette,
  rtl: false,
  shadowWidths: shadowWidths,
  shadows: shadows,
  space: space
};

var useDocument = function useDocument(theme) {
  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      controlledDocument = _useState2[0],
      setControlledDocument = _useState2[1];
  useEffect(function () {
    if (theme && theme.document) {
      setControlledDocument(theme.document);
    } else {
      setControlledDocument(document);
    }
  }, [theme]);
  return controlledDocument;
};

var _excluded = ["theme", "focusVisibleRef", "children"];
var ThemeProvider = function ThemeProvider(_ref) {
  var theme = _ref.theme,
      focusVisibleRef = _ref.focusVisibleRef,
      children = _ref.children,
      other = _objectWithoutProperties(_ref, _excluded);
  var scopeRef = useRef(null);
  var relativeDocument = useDocument(theme);
  var controlledScopeRef = focusVisibleRef === null ? React.createRef() : getControlledValue(focusVisibleRef, scopeRef);
  useFocusVisible({
    scope: controlledScopeRef,
    relativeDocument: relativeDocument
  });
  return React.createElement(ThemeProvider$1, _extends({
    theme: theme
  }, other), focusVisibleRef === undefined ? React.createElement("div", {
    ref: scopeRef
  }, children) : children);
};
ThemeProvider.defaultProps = {
  theme: DEFAULT_THEME
};

function isRtl() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      theme = _ref.theme;
  return Boolean(theme && theme.rtl);
}

function retrieveComponentStyles(componentId, props) {
  var components = props.theme && props.theme.components;
  if (!components) {
    return undefined;
  }
  var componentStyles = components[componentId];
  if (typeof componentStyles === 'function') {
    return componentStyles(props);
  }
  return componentStyles;
}

function withTheme(WrappedComponent) {
  return withTheme$1(WrappedComponent);
}

function getDocument() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      theme = _ref.theme;
  return theme && theme.document || document;
}

var DEFAULT_SHADE = 600;
var adjust = function adjust(color, expected, actual) {
  if (expected !== actual) {
    var amount = Math.abs(expected - actual) / 100 * 0.05;
    return expected > actual ? darken(amount, color) : lighten(amount, color);
  }
  return color;
};
function getColor(hue) {
  var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_SHADE;
  var theme = arguments.length > 2 ? arguments[2] : undefined;
  var transparency = arguments.length > 3 ? arguments[3] : undefined;
  var retVal;
  if (isNaN(shade)) {
    return undefined;
  }
  var palette = theme && theme.palette ? theme.palette : DEFAULT_THEME.palette;
  var colors = theme && theme.colors ? theme.colors : DEFAULT_THEME.colors;
  var _hue;
  if (typeof hue === 'string') {
    _hue = colors[hue] || hue;
  } else {
    _hue = hue;
  }
  if (Object.prototype.hasOwnProperty.call(palette, _hue)) {
    _hue = palette[_hue];
  }
  if (_typeof(_hue) === 'object') {
    retVal = _hue[shade];
    if (!retVal) {
      var _shade = Object.keys(_hue).map(function (hueKey) {
        return parseInt(hueKey, 10);
      }).reduce(function (previous, current) {
        return Math.abs(current - shade) < Math.abs(previous - shade) ? current : previous;
      });
      retVal = adjust(_hue[_shade], shade, _shade);
    }
  } else {
    retVal = adjust(_hue, shade, DEFAULT_SHADE);
  }
  if (transparency) {
    retVal = rgba(retVal, transparency);
  }
  return retVal;
}

function getLineHeight(height, fontSize) {
  var _getValueAndUnit = getValueAndUnit(height.toString()),
      _getValueAndUnit2 = _slicedToArray(_getValueAndUnit, 2),
      heightValue = _getValueAndUnit2[0],
      heightUnit = _getValueAndUnit2[1];
  var _getValueAndUnit3 = getValueAndUnit(fontSize.toString()),
      _getValueAndUnit4 = _slicedToArray(_getValueAndUnit3, 2),
      fontSizeValue = _getValueAndUnit4[0],
      fontSizeUnit = _getValueAndUnit4[1];
  var PIXELS = 'px';
  if (heightUnit && heightUnit !== PIXELS) {
    throw new Error("Unexpected `height` with '".concat(heightUnit, "' units."));
  }
  if (fontSizeUnit && fontSizeUnit !== PIXELS) {
    throw new Error("Unexpected `fontSize` with '".concat(fontSizeUnit, "' units."));
  }
  return heightValue / fontSizeValue;
}

var maxWidth = function maxWidth(breakpoints, breakpoint) {
  var keys = Object.keys(breakpoints);
  var index = keys.indexOf(breakpoint) + 1;
  if (keys[index]) {
    var dimension = getValueAndUnit(breakpoints[keys[index]]);
    var value = dimension[0] - 0.02;
    var unit = dimension[1];
    return "".concat(value).concat(unit);
  }
  return undefined;
};
function mediaQuery(query, breakpoint, theme) {
  var retVal;
  var min;
  var max;
  var breakpoints = theme && theme.breakpoints ? theme.breakpoints : DEFAULT_THEME.breakpoints;
  if (typeof breakpoint === 'string') {
    if (query === 'up') {
      min = breakpoints[breakpoint];
    } else if (query === 'down') {
      if (breakpoint === 'xl') {
        min = DEFAULT_THEME.breakpoints.xs;
      } else {
        max = maxWidth(breakpoints, breakpoint);
      }
    } else if (query === 'only') {
      min = breakpoints[breakpoint];
      max = maxWidth(breakpoints, breakpoint);
    }
  } else if (query === 'between') {
    min = breakpoints[breakpoint[0]];
    max = maxWidth(breakpoints, breakpoint[1]);
  }
  if (min) {
    retVal = "@media (min-width: ".concat(min, ")");
    if (max) {
      retVal = "".concat(retVal, " and (max-width: ").concat(max, ")");
    }
  } else if (max) {
    retVal = "@media (max-width: ".concat(max, ")");
  } else {
    throw new Error("Unexpected query and breakpoint combination: '".concat(query, "', '").concat(breakpoint, "'."));
  }
  return retVal;
}

var exponentialSymbols = {
  symbols: {
    sqrt: {
      func: {
        symbol: 'sqrt',
        f: function f(a) {
          return Math.sqrt(a);
        },
        notation: 'func',
        precedence: 0,
        rightToLeft: 0,
        argCount: 1
      },
      symbol: 'sqrt',
      regSymbol: 'sqrt\\b'
    }
  }
};
var animationStyles$1 = function animationStyles(position, modifier) {
  var property = position.split('-')[0];
  var animationName = keyframes(["0%,66%{", ":2px;border:transparent;}"], property);
  return css(["&", "::before,&", "::after{animation:0.3s ease-in-out ", ";}"], modifier, modifier, animationName);
};
var positionStyles = function positionStyles(position, size, inset) {
  var margin = math("".concat(size, " / -2"));
  var placement = math("".concat(margin, " + ").concat(inset));
  var clipPath;
  var positionCss;
  var propertyRadius;
  if (position.startsWith('top')) {
    propertyRadius = 'border-bottom-right-radius';
    clipPath = 'polygon(100% 0, 100% 1px, 1px 100%, 0 100%, 0 0)';
    positionCss = css(["top:", ";right:", ";left:", ";margin-left:", ";"], placement, position === 'top-right' && size, position === 'top' ? '50%' : position === 'top-left' && size, position === 'top' && margin);
  } else if (position.startsWith('right')) {
    propertyRadius = 'border-bottom-left-radius';
    clipPath = 'polygon(100% 0, 100% 100%, calc(100% - 1px) 100%, 0 1px, 0 0)';
    positionCss = css(["top:", ";right:", ";bottom:", ";margin-top:", ";"], position === 'right' ? '50%' : position === 'right-top' && size, placement, position === 'right-bottom' && size, position === 'right' && margin);
  } else if (position.startsWith('bottom')) {
    propertyRadius = 'border-top-left-radius';
    clipPath = 'polygon(100% 0, calc(100% - 1px) 0, 0 calc(100% - 1px), 0 100%, 100% 100%)';
    positionCss = css(["right:", ";bottom:", ";left:", ";margin-left:", ";"], position === 'bottom-right' && size, placement, position === 'bottom' ? '50%' : position === 'bottom-left' && size, position === 'bottom' && margin);
  } else if (position.startsWith('left')) {
    propertyRadius = 'border-top-right-radius';
    clipPath = 'polygon(0 100%, 100% 100%, 100% calc(100% - 1px), 1px 0, 0 0)';
    positionCss = css(["top:", ";bottom:", ";left:", ";margin-top:", ";"], position === 'left' ? '50%' : position === 'left-top' && size, size, placement, position === 'left' && margin);
  }
  return css(["&::before{", ":100%;clip-path:", ";}&::before,&::after{", "}"], propertyRadius, clipPath, positionCss);
};
function arrowStyles(position) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var size = options.size || '6px';
  var inset = options.inset || '0';
  var squareSize = math("".concat(size, " * 2 / sqrt(2)"), exponentialSymbols);
  return css(["position:relative;&::before{border-width:inherit;border-style:inherit;border-color:transparent;background-clip:content-box;}&::after{z-index:-1;border:inherit;box-shadow:inherit;}&::before,&::after{position:absolute;transform:rotate(45deg);background-color:inherit;box-sizing:inherit;width:", ";height:", ";content:'';}", ";", ";"], squareSize, squareSize, positionStyles(position, squareSize, inset), options.animationModifier && animationStyles$1(position, options.animationModifier));
}

var animationStyles = function animationStyles(position, options) {
  var theme = options.theme || DEFAULT_THEME;
  var translateValue = "".concat(theme.space.base * 5, "px");
  var transformFunction;
  if (position === 'top') {
    transformFunction = 'translateY';
  } else if (position === 'right') {
    transformFunction = 'translateX';
    translateValue = "-".concat(translateValue);
  } else if (position === 'bottom') {
    transformFunction = 'translateY';
    translateValue = "-".concat(translateValue);
  } else {
    transformFunction = 'translateX';
  }
  var animationName = keyframes(["0%{transform:", "(", ");}"], transformFunction, translateValue);
  return css(["&", " ", "{animation:0.2s cubic-bezier(0.15,0.85,0.35,1.2) ", ";}"], options.animationModifier, options.childSelector || '> *', animationName);
};
var hiddenStyles = function hiddenStyles(options) {
  var transition = 'opacity 0.2s ease-in-out, 0.2s visibility 0s linear';
  return css(["transition:", ";visibility:hidden;opacity:0;"], options.animationModifier && transition);
};
function menuStyles(position) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var theme = options.theme || DEFAULT_THEME;
  var marginProperty;
  if (position === 'top') {
    marginProperty = 'margin-bottom';
  } else if (position === 'right') {
    marginProperty = 'margin-left';
  } else if (position === 'bottom') {
    marginProperty = 'margin-top';
  } else {
    marginProperty = 'margin-right';
  }
  return css(["position:absolute;z-index:", ";", ":", ";line-height:0;font-size:0.01px;& ", "{display:inline-block;position:relative;margin:0;box-sizing:border-box;border:", " ", ";border-radius:", ";box-shadow:", ";background-color:", ";cursor:default;padding:0;text-align:", ";white-space:normal;font-size:", ";font-weight:", ";direction:", ";:focus{outline:none;}}", ";", ";"], options.zIndex || 0, marginProperty, options.margin, options.childSelector || '> *', theme.borders.sm, getColor('neutralHue', 300, theme), theme.borderRadii.md, theme.shadows.lg("".concat(theme.space.base * 5, "px"), "".concat(theme.space.base * 7.5, "px"), getColor('chromeHue', 600, theme, 0.15)), theme.colors.background, theme.rtl ? 'right' : 'left', theme.fontSizes.md, theme.fontWeights.regular, theme.rtl && 'rtl', options.animationModifier && animationStyles(position, options), options.hidden && hiddenStyles(options));
}

export { DEFAULT_THEME, PALETTE, ThemeProvider, arrowStyles, getColor, getDocument, getLineHeight, isRtl, mediaQuery, menuStyles, retrieveComponentStyles, retrieveComponentStyles as retrieveTheme, useDocument, withTheme };
