import React, { Children, useMemo } from 'react';

import { LayoutContainer } from './LayoutContainer';
import { LayoutRoot } from './LayoutRoot';

import { useEditorContext } from '../contexts';
import { renderTree } from '../../utils';

export function LayoutEditor() {
  const {
    context: { components },
    tree,
  } = useEditorContext();

  const renderedTree = useMemo(
    () =>
      Children.toArray(renderTree(tree, { components, container: LayoutContainer })),
    [tree, components],
  );

  return <LayoutRoot>{renderedTree}</LayoutRoot>;
}
