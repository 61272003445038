import { Fragment, Children, createElement, isValidElement } from 'react';

function flattenTreeElements(tree) {
  const [node, , children] = tree;

  return [node, ...(children || []).map(flattenTreeElements)].flat();
}

export function isPrimitiveType(node) {
  return ['string', 'boolean', 'number'].includes(node.type);
}

export function renderTreeCodeStructure(tree, components, container, level = 0) {
  const [node, , children] = tree;
  const type = node.type === 'view' ? 'Fragment' : node.type;

  if (type === 'Fragment') {
    return children
      .map(
        (child) => `${renderTreeCodeStructure(child, components, container, level)}`,
      )
      .join('\n');
  } else {
    ++level;
  }

  if (isPrimitiveType(node) || children.length === 0) {
    const valueType = node.inputData[node.type];
    const value = valueType
      ? node.inputData[node.type][node.type]
      : `<${node.type} />`;
    return [`\t`.repeat(level), value].join('');
  }

  return [
    `${`\t`.repeat(level)}<${type}>`,
    `${children
      .map(
        (child) => `${renderTreeCodeStructure(child, components, container, level)}`,
      )
      .join('\n')}`,
    `${`\t`.repeat(level)}</${type}>`,
  ].join('\n');
}

function renderComponentImportStatements(tree, components) {
  const importedComponents = flattenTreeElements(tree)
    .filter(
      (node) => !(isPrimitiveType(node) || ['view', 'Fragment'].includes(node.type)),
    )
    .map((node) => node.type);

  const importStatements = [
    ['react', 'React, { Fragment }'],
    ...[
      ...components
        .filter((component) => {
          return importedComponents.includes(component.name);
        })
        .reduce((map, component) => {
          if (map.has(component.package)) {
            map.get(component.package).add(component.name);
          } else {
            map.set(component.package, new Set([component.name]));
          }
          return map;
        }, new Map())
        .entries(),
    ].map(([pkg, imports]) => [pkg, `{ ${[...imports].join(', ')} }`]),
  ];

  return importStatements
    .map(([source, imports]) => `import ${imports} from '${source}';`)
    .join('\n');
}

export function renderTreeCode(tree, components, container) {
  // TODO: use view title to name `View`, annotate with description
  // TODO: include props in markup
  // TODO: sync code edits with graph/layout
  // TODO: add functionality to move around declarative blocks as palpable items (reorder, remove, etc)
  // TODO: add dependencies based on usage
  // const usedImports = flattenTree(tree).reduce((map, ), new Map());
  return [
    renderComponentImportStatements(tree, components),
    '',
    'export default function View() {',
    `\treturn (`,
    renderTreeCodeStructure(tree, components, container, 1),
    `\t);`,
    '}',
  ].join('\n');
}

export function renderTree(tree, { components, container } = {}) {
  const [node, props, children] = tree;

  if (!node) return null;

  if (isPrimitiveType(node)) return node.inputData[node.type][node.type];

  const component = components[node.type] || Fragment;

  const element = createElement(
    component,
    props,
    children && children.length > 1
      ? createElement(
          Fragment,
          undefined,
          Children.toArray(
            children
              .map((child) => renderTree(child, { components, container }))
              .filter(isValidElement),
          ),
        )
      : children.length > 0
      ? renderTree(children[0], { components, container })
      : null,
  );

  if (container) {
    return createElement(container, { node, component }, element);
  }

  return element;
}
