/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useReducer, useEffect } from 'react';
import { getControlledValue, composeEventHandlers, KEY_CODES } from '@zendeskgarden/container-utilities';
import PropTypes from 'prop-types';

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

var _excluded$1 = ["role"],
    _excluded2 = ["selectedAriaKey", "role", "onFocus", "onKeyDown", "onClick", "item", "focusRef", "refKey"];
function stateReducer(state, action) {
  switch (action.type) {
    case 'END':
    case 'HOME':
    case 'FOCUS':
    case 'INCREMENT':
    case 'DECREMENT':
      {
        return _extends({}, state, {
          focusedItem: action.payload
        });
      }
    case 'MOUSE_SELECT':
      {
        return _extends({}, state, {
          selectedItem: action.payload,
          focusedItem: undefined
        });
      }
    case 'KEYBOARD_SELECT':
      {
        return _extends({}, state, {
          selectedItem: action.payload
        });
      }
    case 'EXIT_WIDGET':
      {
        return _extends({}, state, {
          focusedItem: undefined
        });
      }
    default:
      return state;
  }
}
function useSelection(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$direction = _ref.direction,
      direction = _ref$direction === void 0 ? 'horizontal' : _ref$direction,
      _ref$defaultFocusedIn = _ref.defaultFocusedIndex,
      defaultFocusedIndex = _ref$defaultFocusedIn === void 0 ? 0 : _ref$defaultFocusedIn,
      defaultSelectedIndex = _ref.defaultSelectedIndex,
      rtl = _ref.rtl,
      selectedItem = _ref.selectedItem,
      focusedItem = _ref.focusedItem,
      onSelect = _ref.onSelect,
      onFocus = _ref.onFocus;
  var isSelectedItemControlled = selectedItem !== undefined;
  var isFocusedItemControlled = focusedItem !== undefined;
  var refs = [];
  var items = [];
  var _useReducer = useReducer(stateReducer, {
    selectedItem: selectedItem,
    focusedItem: focusedItem
  }),
      state = _useReducer[0],
      dispatch = _useReducer[1];
  var controlledFocusedItem = getControlledValue(focusedItem, state.focusedItem);
  var controlledSelectedItem = getControlledValue(selectedItem, state.selectedItem);
  useEffect(function () {
    if (controlledFocusedItem !== undefined) {
      var focusedIndex = items.indexOf(controlledFocusedItem);
      refs[focusedIndex] && refs[focusedIndex].current.focus();
    }
  }, [controlledFocusedItem]);
  useEffect(function () {
    if (selectedItem === undefined && defaultSelectedIndex !== undefined) {
      onSelect && onSelect(items[defaultSelectedIndex]);
      if (!isSelectedItemControlled) {
        dispatch({
          type: 'KEYBOARD_SELECT',
          payload: items[defaultSelectedIndex]
        });
      }
    }
  }, []);
  var getContainerProps = function getContainerProps(_temp2) {
    var _ref2 = _temp2 === void 0 ? {} : _temp2,
        _ref2$role = _ref2.role,
        role = _ref2$role === void 0 ? 'listbox' : _ref2$role,
        other = _objectWithoutPropertiesLoose(_ref2, _excluded$1);
    return _extends({
      role: role,
      'data-garden-container-id': 'containers.selection',
      'data-garden-container-version': '1.3.15'
    }, other);
  };
  var getItemProps = function getItemProps(_temp3, propGetterName) {
    var _extends2;
    var _ref3 = _temp3 === void 0 ? {} : _temp3,
        _ref3$selectedAriaKey = _ref3.selectedAriaKey,
        selectedAriaKey = _ref3$selectedAriaKey === void 0 ? 'aria-selected' : _ref3$selectedAriaKey,
        _ref3$role = _ref3.role,
        role = _ref3$role === void 0 ? 'option' : _ref3$role,
        onFocusCallback = _ref3.onFocus,
        onKeyDown = _ref3.onKeyDown,
        onClick = _ref3.onClick,
        item = _ref3.item,
        focusRef = _ref3.focusRef,
        _ref3$refKey = _ref3.refKey,
        refKey = _ref3$refKey === void 0 ? 'ref' : _ref3$refKey,
        other = _objectWithoutPropertiesLoose(_ref3, _excluded2);
    if (propGetterName === void 0) {
      propGetterName = 'getItemProps';
    }
    if (item === undefined) {
      throw new Error("Accessibility Error: You must provide an \"item\" option to \"" + propGetterName + "()\"");
    }
    if (focusRef === undefined) {
      throw new Error("Accessibility Error: You must provide a \"focusRef\" option to \"" + propGetterName + "()\"");
    }
    refs.push(focusRef);
    items.push(item);
    var isSelected = controlledSelectedItem === item;
    var isFocused = controlledFocusedItem === undefined ? isSelected : controlledFocusedItem === item;
    var tabIndex = isFocused || controlledSelectedItem === undefined && controlledFocusedItem === undefined && items.indexOf(item) === defaultFocusedIndex ? 0 : -1;
    var verticalDirection = direction === 'vertical' || direction === 'both';
    var horizontalDirection = direction === 'horizontal' || direction === 'both';
    return _extends((_extends2 = {
      role: role,
      tabIndex: tabIndex
    }, _extends2[selectedAriaKey] = selectedAriaKey ? isSelected : undefined, _extends2[refKey] = focusRef, _extends2.onFocus = composeEventHandlers(onFocusCallback, function () {
      onFocus && onFocus(item);
      if (!isFocusedItemControlled) {
        dispatch({
          type: 'FOCUS',
          payload: item
        });
      }
    }), _extends2.onBlur = function onBlur(e) {
      if (e.target.tabIndex === 0) {
        if (!isFocusedItemControlled) {
          dispatch({
            type: 'EXIT_WIDGET'
          });
        }
        onFocus && onFocus();
      }
    }, _extends2.onClick = composeEventHandlers(onClick, function () {
      onSelect && onSelect(item);
      onFocus && onFocus();
      if (!isSelectedItemControlled) {
        dispatch({
          type: 'MOUSE_SELECT',
          payload: item
        });
      }
    }), _extends2.onKeyDown = composeEventHandlers(onKeyDown, function (e) {
      var nextIndex;
      var currentIndex;
      if (isFocusedItemControlled) {
        currentIndex = items.indexOf(focusedItem);
      } else {
        currentIndex = items.indexOf(state.focusedItem || state.selectedItem);
      }
      var onIncrement = function onIncrement() {
        nextIndex = currentIndex + 1;
        if (currentIndex === items.length - 1) {
          nextIndex = 0;
        }
        !isFocusedItemControlled && dispatch({
          type: 'INCREMENT',
          payload: items[nextIndex]
        });
        onFocus && onFocus(items[nextIndex]);
      };
      var onDecrement = function onDecrement() {
        nextIndex = currentIndex - 1;
        if (currentIndex === 0) {
          nextIndex = items.length - 1;
        }
        !isFocusedItemControlled && dispatch({
          type: 'DECREMENT',
          payload: items[nextIndex]
        });
        onFocus && onFocus(items[nextIndex]);
      };
      var hasModifierKeyPressed = e.ctrlKey || e.metaKey || e.shiftKey || e.altKey;
      if (!hasModifierKeyPressed) {
        if (e.keyCode === KEY_CODES.UP && verticalDirection || e.keyCode === KEY_CODES.LEFT && horizontalDirection) {
          if (rtl && !verticalDirection) {
            onIncrement();
          } else {
            onDecrement();
          }
          e.preventDefault();
        } else if (e.keyCode === KEY_CODES.DOWN && verticalDirection || e.keyCode === KEY_CODES.RIGHT && horizontalDirection) {
          if (rtl && !verticalDirection) {
            onDecrement();
          } else {
            onIncrement();
          }
          e.preventDefault();
        } else if (e.keyCode === KEY_CODES.HOME) {
          if (!isFocusedItemControlled) {
            dispatch({
              type: 'HOME',
              payload: items[0]
            });
          }
          onFocus && onFocus(items[0]);
          e.preventDefault();
        } else if (e.keyCode === KEY_CODES.END) {
          if (!isFocusedItemControlled) {
            dispatch({
              type: 'END',
              payload: items[items.length - 1]
            });
          }
          onFocus && onFocus(items[items.length - 1]);
          e.preventDefault();
        } else if (e.keyCode === KEY_CODES.SPACE || e.keyCode === KEY_CODES.ENTER) {
          onSelect && onSelect(item);
          if (!isSelectedItemControlled) {
            dispatch({
              type: 'KEYBOARD_SELECT',
              payload: item
            });
          }
          e.preventDefault();
        }
      }
    }), _extends2), other);
  };
  return {
    focusedItem: controlledFocusedItem,
    selectedItem: controlledSelectedItem,
    getItemProps: getItemProps,
    getContainerProps: getContainerProps
  };
}

var _excluded = ["children", "render"];
var SelectionContainer = function SelectionContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
  return React.createElement(React.Fragment, null, render(useSelection(options)));
};
SelectionContainer.defaultProps = {
  direction: 'horizontal',
  defaultFocusedIndex: 0
};
SelectionContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  rtl: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  defaultFocusedIndex: PropTypes.number,
  focusedItem: PropTypes.any,
  selectedItem: PropTypes.any,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func
};

export { SelectionContainer, useSelection };
