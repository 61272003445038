import React, { useState, useCallback, useRef, useMemo, forwardRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

import { useEditorContext } from '../contexts';

export const Plane = styled.div`
  width: 100%;
  heght: 100%;

  position: inherit;
  display: inherit;
  flex: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  min-width: inherit;
  width: inherit;
  wrap: inherit;
  margin-right: inherit;
  margin-left: inherit;
  margin: inherit;
  padding: inherit;
  flex-basis: inherit;
  flex-grow: inherit;
  flex-shrink: inherit;
  align-self: inherit;
  order: inherit;

  outline: 1px dashed gray;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  background-color: ${(props) => props.isOver && 'brown'};

  transform: ${(props) => props.isDragging && `scale(0.95)`};
  transform: ${(props) => props.isOver && `scale(1.05)`};
`;

// eslint-disable-next-line react/prop-types
export function LayoutNode({ type, component, children }) {
  const Component = useMemo(
    // eslint-disable-next-line react/display-name
    () => component || forwardRef(() => children),
    [component],
  );
  // const Component = useMemo(
  //   () =>
  //     component
  //       ? styled(component)`
  //           outline: 1px dashed gray;
  //           opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  //           background-color: ${(props) => props.isOver && 'orange'};

  //           transform: ${(props) => props.isDragging && `scale(0.95)`};
  //           transform: ${(props) => props.isOver && `scale(1.05)`};
  //         `
  //       : // eslint-disable-next-line react/display-name
  //         forwardRef(() => children),
  //   [component],
  // );

  const [selected, setSelected] = useState(false);
  const {
    editor: {
      layout: { itemTypes },
    },
  } = useEditorContext();
  const ref = useRef();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: itemTypes.node,
    item: {
      type: type || itemTypes.node,
    },
    collect: (monitor) => ({
      dropped: monitor.didDrop(),
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [itemTypes.node],
    drop: (item, monitor) => {
      console.log('dropping node', item, monitor.didDrop());
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      console.log(selected);
      setSelected(true);
    },
    [selected],
  );

  return (
    <Component
      ref={drag(drop(ref))}
      onClick={onClick}
      canDrop={canDrop}
      isDragging={isDragging}
      isOver={isOver}
    >
      {children}
    </Component>
  );
  // return (
  //   <Plane
  //     ref={drag(drop(ref))}
  //     onClick={onClick}
  //     canDrop={canDrop}
  //     isDragging={isDragging}
  //     isOver={isOver}
  //   >
  //     <Component>{children}</Component>
  //   </Plane>
  // );
}
