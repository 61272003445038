/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { createContext, useContext, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import mergeRefs from 'react-merge-refs';
import styled, { css, ThemeContext } from 'styled-components';
import { retrieveComponentStyles, DEFAULT_THEME, getColor } from '@zendeskgarden/react-theming';
import { stripUnit } from 'polished';
import { useTabs } from '@zendeskgarden/container-tabs';
import { getControlledValue } from '@zendeskgarden/container-utilities';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

var COMPONENT_ID$3 = 'tabs.tab';
var colorStyles = function colorStyles(_ref) {
  var theme = _ref.theme,
      isSelected = _ref.isSelected;
  var selectedColor = getColor('primaryHue', 600, theme);
  return css(["border-color:", ";color:", ";&:hover{color:", ";}&:active{border-color:currentColor;color:", ";}&[data-garden-focus-visible]{color:", ";}&[data-garden-focus-visible]::before{box-shadow:inset ", ";}&[aria-disabled='true']{border-color:transparent;color:", ";}"], isSelected && 'currentColor !important', isSelected ? selectedColor : 'inherit', selectedColor, selectedColor, selectedColor, theme.shadows.sm(getColor('primaryHue', 600, theme, 0.35)), function (props) {
    return getColor('neutralHue', 400, props.theme);
  });
};
var sizeStyles = function sizeStyles(_ref2) {
  var theme = _ref2.theme;
  var paddingTop = theme.space.base * 2.5;
  var paddingHorizontal = theme.space.base * 7;
  var paddingBottom = paddingTop - stripUnit(theme.borderWidths.md) - stripUnit(theme.borderWidths.sm);
  return css(["padding:", "px ", "px ", "px;"], paddingTop, paddingHorizontal, paddingBottom);
};
var StyledTab = styled.div.attrs(function (props) {
  return {
    'data-garden-id': COMPONENT_ID$3,
    'data-garden-version': '8.47.2',
    'aria-disabled': props.disabled
  };
}).withConfig({
  displayName: "StyledTab",
  componentId: "sc-x2pbow-0"
})(["display:inline-block;position:relative;transition:color 0.25s ease-in-out;border-bottom:", " transparent;border-width:", ";cursor:pointer;overflow:hidden;vertical-align:top;user-select:none;text-align:center;text-decoration:none;text-overflow:ellipsis;", " ", " &:focus{outline:none;text-decoration:none;}&[data-garden-focus-visible]::before{position:absolute;top:", "px;right:", "px;left:", "px;border-radius:", ";height:", "px;pointer-events:none;}&::before{transition:box-shadow 0.1s ease-in-out;content:'';}&:active::before{box-shadow:none;}&[aria-disabled='true']{cursor:default;}", ";"], function (props) {
  return props.theme.borderStyles.solid;
}, function (props) {
  return props.theme.borderWidths.md;
}, sizeStyles, colorStyles, function (props) {
  return props.theme.space.base * 2.5;
}, function (props) {
  return props.theme.space.base * 6;
}, function (props) {
  return props.theme.space.base * 6;
}, function (props) {
  return props.theme.borderRadii.md;
}, function (props) {
  return props.theme.space.base * 5;
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$3, props);
});
StyledTab.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID$2 = 'tabs.tablist';
var StyledTabList = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$2,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledTabList",
  componentId: "sc-wa5aaj-0"
})(["display:block;margin-top:0;margin-bottom:", "px;border-bottom:", " ", " ", ";padding:0;line-height:", "px;white-space:nowrap;color:", ";font-size:", ";", ";"], function (props) {
  return props.theme.space.base * 5;
}, function (props) {
  return props.theme.borderWidths.sm;
}, function (props) {
  return props.theme.borderStyles.solid;
}, function (props) {
  return getColor('neutralHue', 300, props.theme);
}, function (props) {
  return props.theme.space.base * 5;
}, function (props) {
  return getColor('neutralHue', 600, props.theme);
}, function (props) {
  return props.theme.fontSizes.md;
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$2, props);
});
StyledTabList.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID$1 = 'tabs.tabpanel';
var StyledTabPanel = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$1,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledTabPanel",
  componentId: "sc-7lhrmp-0"
})(["display:block;&[aria-hidden='true']{display:none;}", ";"], function (props) {
  return retrieveComponentStyles(COMPONENT_ID$1, props);
});
StyledTabPanel.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID = 'tabs.tabs';
var verticalStyling = function verticalStyling(_ref) {
  var theme = _ref.theme;
  return css(["display:table;", "{display:table-cell;margin-bottom:0;border-bottom:none;vertical-align:top;}", "{display:block;margin-bottom:", "px;margin-left:", ";border-left:", ";border-bottom-style:none;border-", "-style:", ";border-", "-color:transparent;padding:", "px ", "px;text-align:", ";&:last-of-type{margin-bottom:0;}&[data-garden-focus-visible]::before{top:", "px;right:", "px;left:", "px;}}", "{margin-", ":", "px;vertical-align:top;}"], StyledTabList, StyledTab, theme.space.base * 5, theme.rtl && '0', theme.rtl && '0', theme.rtl ? 'right' : 'left', theme.borderStyles.solid, theme.rtl ? 'right' : 'left', theme.space.base, theme.space.base * 2, theme.rtl ? 'right' : 'left', theme.space.base, theme.space.base, theme.space.base, StyledTabPanel, theme.rtl ? 'right' : 'left', theme.space.base * 8);
};
var StyledTabs = styled.div.attrs({
  'data-garden-id': COMPONENT_ID,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledTabs",
  componentId: "sc-1qaor65-0"
})(["display:block;overflow:hidden;direction:", ";", ";", ";"], function (props) {
  return props.theme.rtl && 'rtl';
}, function (props) {
  return props.isVertical && verticalStyling(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID, props);
});
StyledTabs.defaultProps = {
  theme: DEFAULT_THEME
};

var TabsContext = createContext(undefined);
var useTabsContext = function useTabsContext() {
  return useContext(TabsContext);
};

var _excluded$2 = ["disabled", "item"];
var Tab = React.forwardRef(function (_ref, ref) {
  var disabled = _ref.disabled,
      item = _ref.item,
      otherProps = _objectWithoutProperties(_ref, _excluded$2);
  var tabsPropGetters = useTabsContext();
  var tabRef = useRef();
  if (disabled || !tabsPropGetters) {
    return React.createElement(StyledTab, _extends({
      disabled: disabled,
      ref: mergeRefs([tabRef, ref])
    }, otherProps));
  }
  return React.createElement(StyledTab, _extends({
    ref: mergeRefs([tabRef, ref])
  }, tabsPropGetters.getTabProps(_objectSpread2({
    item: item,
    focusRef: tabRef,
    index: tabsPropGetters.tabIndexRef.current++,
    isSelected: item === tabsPropGetters.selectedItem
  }, otherProps))));
});
Tab.displayName = 'Tab';
Tab.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.any
};

var TabList = React.forwardRef(function (props, ref) {
  var tabsPropGetters = useTabsContext();
  if (!tabsPropGetters) {
    return React.createElement(StyledTabList, _extends({
      ref: ref
    }, props));
  }
  return React.createElement(StyledTabList, tabsPropGetters.getTabListProps(_objectSpread2({
    ref: ref
  }, props)));
});
TabList.displayName = 'TabList';

var _excluded$1 = ["item"];
var TabPanel = React.forwardRef(function (_ref, ref) {
  var item = _ref.item,
      otherProps = _objectWithoutProperties(_ref, _excluded$1);
  var tabsPropGetters = useTabsContext();
  if (!tabsPropGetters) {
    return React.createElement(StyledTabPanel, _extends({
      ref: ref
    }, otherProps));
  }
  return React.createElement(StyledTabPanel, tabsPropGetters.getTabPanelProps(_objectSpread2({
    item: item,
    ref: ref,
    index: tabsPropGetters.tabPanelIndexRef.current++,
    'aria-hidden': tabsPropGetters.selectedItem !== item
  }, otherProps)));
});
TabPanel.displayName = 'TabPanel';
TabPanel.propTypes = {
  item: PropTypes.any
};

var _excluded = ["isVertical", "children", "onChange", "selectedItem"];
var Tabs = React.forwardRef(function (_ref, ref) {
  var isVertical = _ref.isVertical,
      children = _ref.children,
      onChange = _ref.onChange,
      controlledSelectedItem = _ref.selectedItem,
      otherProps = _objectWithoutProperties(_ref, _excluded);
  var theme = useContext(ThemeContext) || DEFAULT_THEME;
  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      internalSelectedItem = _useState2[0],
      setSelectedItem = _useState2[1];
  var tabIndexRef = useRef(0);
  var tabPanelIndexRef = useRef(0);
  var selectedItem = getControlledValue(controlledSelectedItem, internalSelectedItem);
  var tabPropGetters = useTabs({
    rtl: theme.rtl,
    vertical: isVertical,
    selectedItem: selectedItem,
    defaultSelectedIndex: 0,
    onSelect: function onSelect(item) {
      if (onChange) {
        onChange(item);
      } else {
        setSelectedItem(item);
      }
    }
  });
  var tabsContextValue = useMemo(function () {
    return _objectSpread2(_objectSpread2({}, tabPropGetters), {}, {
      tabIndexRef: tabIndexRef,
      tabPanelIndexRef: tabPanelIndexRef
    });
  }, [tabPropGetters]);
  return React.createElement(TabsContext.Provider, {
    value: tabsContextValue
  }, React.createElement(StyledTabs, _extends({
    isVertical: isVertical
  }, otherProps, {
    ref: ref
  }), children));
});
Tabs.propTypes = {
  isVertical: PropTypes.bool,
  selectedItem: PropTypes.any,
  onChange: PropTypes.func
};
Tabs.defaultProps = {
  isVertical: false
};
Tabs.displayName = 'Tabs';

export { Tab, TabList, TabPanel, Tabs };
