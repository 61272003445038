/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useState } from 'react';
import { useUIDSeed } from 'react-uid';
import PropTypes from 'prop-types';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded = ["id", "htmlFor"],
    _excluded2 = ["id"],
    _excluded3 = ["id"];
function useField(idPrefix) {
  var seed = useUIDSeed();
  var _useState = useState(idPrefix || seed("field_" + '1.3.9')),
      prefix = _useState[0];
  var inputId = prefix + "--input";
  var labelId = prefix + "--label";
  var hintId = prefix + "--hint";
  var getLabelProps = function getLabelProps(_temp) {
    var _ref = _temp === void 0 ? {} : _temp,
        _ref$id = _ref.id,
        id = _ref$id === void 0 ? labelId : _ref$id,
        _ref$htmlFor = _ref.htmlFor,
        htmlFor = _ref$htmlFor === void 0 ? inputId : _ref$htmlFor,
        other = _objectWithoutPropertiesLoose(_ref, _excluded);
    return _extends({
      id: id,
      htmlFor: htmlFor,
      'data-garden-container-id': 'containers.field',
      'data-garden-container-version': '1.3.9'
    }, other);
  };
  var getInputProps = function getInputProps(_temp2, _temp3) {
    var _ref2 = _temp2 === void 0 ? {} : _temp2,
        _ref2$id = _ref2.id,
        id = _ref2$id === void 0 ? inputId : _ref2$id,
        other = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    var _ref3 = _temp3 === void 0 ? {} : _temp3,
        _ref3$isDescribed = _ref3.isDescribed,
        isDescribed = _ref3$isDescribed === void 0 ? false : _ref3$isDescribed;
    return _extends({
      id: id,
      'aria-labelledby': labelId,
      'aria-describedby': isDescribed ? hintId : null
    }, other);
  };
  var getHintProps = function getHintProps(_temp4) {
    var _ref4 = _temp4 === void 0 ? {} : _temp4,
        _ref4$id = _ref4.id,
        id = _ref4$id === void 0 ? hintId : _ref4$id,
        other = _objectWithoutPropertiesLoose(_ref4, _excluded3);
    return _extends({
      id: id
    }, other);
  };
  return {
    getLabelProps: getLabelProps,
    getInputProps: getInputProps,
    getHintProps: getHintProps
  };
}

var FieldContainer = function FieldContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      id = _ref.id;
  return React.createElement(React.Fragment, null, render(useField(id)));
};
FieldContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  id: PropTypes.string
};

export { FieldContainer, useField };
