import React from 'react';
import { Grid } from '@zendeskgarden/react-grid';

import { GraphsChart } from './Graphs';
import { ComponentsChart } from './Components';

export function Catalog() {
  return (
    <Grid>
      <GraphsChart />
      <ComponentsChart />
    </Grid>
  );
}
