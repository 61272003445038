export const IFRAME_PATH = '/iframe';
export const NODES_KEY = 'NODES';

export const itemTypes = {
  root: 'ROOT',
  row: 'ROW',
  column: 'COLUMN',
  element: 'ELEMENT',
  node: 'NODE',
};
