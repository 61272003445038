import React, { useRef } from 'react';
import { NodeEditor } from 'flume';

import { useEditorContext } from '../contexts';

export function GraphEditor() {
  const nodeEditorRef = useRef();
  const { graph: { nodes, updateNodes, config } = {}, context } = useEditorContext();

  return (
    <div style={{ width: '100%', height: '50vh' }}>
      <NodeEditor
        ref={nodeEditorRef}
        portTypes={config.portTypes}
        nodeTypes={config.nodeTypes}
        context={context}
        nodes={nodes}
        onChange={updateNodes}
        defaultNodes={[{ type: 'view' }]}
        initialScale={0.5}
      />
    </div>
  );
}
