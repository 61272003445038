import { useMemo } from 'react';

import testGraph from '../../meta/graphs/test-graph.json';
import simpleButtonGraph from '../../meta/graphs/simple-button-graph.json';

export function useGraphs() {
  // placeholder for server side driven graphs
  return useMemo(() => [
    testGraph,
    simpleButtonGraph,
    {
      name: 'Chrome Graph',
      description: 'layout',
      graph: {},
      extensions: [],
    },
    {
      name: 'Table Pattern Graph',
      description: 'pattern with inputs',
      graph: {},
      extensions: [],
    },
    // {
    //   name: 'Notification Graph',
    //   description: 'toaster',
    //   graph: {},
    //   extensions: [],
    // },
    // {
    //   name: 'Validation Logic Graph',
    //   description: 'description',
    //   graph: {},
    //   extensions: [],
    // },
  ]);
}
