import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';

import { useEditorContext } from '../contexts';

export const LayoutBase = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;

  // compensate for Sheet invisible border
  border: 1px solid transparent;
  border-right: none;
`;

// TODO: add grid lines as optional toggle
export const LayoutGrid = styled.div`
  width: 100%;
  min-height: 100%;
  outline: 1px dotted blue;
  margin: 1px;
  background-color: ${(props) => props.isOver && props.canDrop && 'orange'};
  ${(props) =>
    props.isDragging &&
    `
    & * {
      transform: scale(0.5);
    }
  `}
`;

// eslint-disable-next-line react/prop-types
export function LayoutRoot({ children }) {
  const {
    editor: {
      layout: { itemTypes },
      settings: { showOutlines },
    },
    graph,
    addNode,
  } = useEditorContext();

  const [{ isOver, canDrop, isDragging }, drop] = useDrop(() => ({
    accept: [itemTypes.node],
    drop: (item, monitor) => {
      if (monitor.didDrop() === false) {
        addNode(item, graph.rootNode);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  }));

  return (
    <LayoutBase>
      <LayoutGrid
        canDrop={canDrop}
        isOver={showOutlines || (isOver && canDrop)}
        dragging={isDragging}
        ref={drop}
      >
        {children}
      </LayoutGrid>
    </LayoutBase>
  );
}
