/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { math } from 'polished';
import { retrieveComponentStyles, DEFAULT_THEME, getColor } from '@zendeskgarden/react-theming';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

var ARRAY_ALIGN_ITEMS = ['start', 'end', 'center', 'baseline', 'stretch'];
var ARRAY_ALIGN_SELF = ['auto', 'start', 'end', 'center', 'baseline', 'stretch'];
var ARRAY_DIRECTION = ['row', 'row-reverse', 'column', 'column-reverse'];
var ARRAY_JUSTIFY_CONTENT = ['start', 'end', 'center', 'between', 'around'];
var ARRAY_TEXT_ALIGN = ['start', 'end', 'center', 'justify'];
var ARRAY_SPACE = [false, 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
var ARRAY_WRAP = ['nowrap', 'wrap', 'wrap-reverse'];

var COMPONENT_ID$2 = 'grid.col';
var colorStyles$2 = function colorStyles(props) {
  var backgroundColor = getColor('primaryHue', 600, props.theme, 0.1);
  return css(["background-clip:content-box;background-color:", ";"], backgroundColor);
};
var flexStyles$1 = function flexStyles(size, alignSelf, textAlign, offset, order, props) {
  var margin = offset && "".concat(math("".concat(offset, " / ").concat(props.columns, " * 100")), "%");
  var flexBasis;
  var flexGrow;
  var maxWidth;
  var width;
  if (typeof size === 'boolean') {
    flexBasis = 0;
    flexGrow = 1;
    maxWidth = '100%';
  } else if (size === 'auto') {
    flexBasis = 'auto';
    flexGrow = 0;
    maxWidth = '100%';
    width = 'auto';
  } else if (size !== undefined) {
    flexBasis = "".concat(math("".concat(size, " / ").concat(props.columns, " * 100")), "%");
    flexGrow = 0;
    maxWidth = flexBasis;
  }
  var horizontalAlign;
  if (textAlign === 'start') {
    horizontalAlign = props.theme.rtl ? 'right' : 'left';
  } else if (textAlign === 'end') {
    horizontalAlign = props.theme.rtl ? 'left' : 'right';
  } else {
    horizontalAlign = textAlign;
  }
  var flexOrder;
  if (order === 'first') {
    flexOrder = -1;
  } else if (order === 'last') {
    flexOrder = math("".concat(props.columns, " + 1"));
  } else {
    flexOrder = order;
  }
  return css(["flex-basis:", ";flex-grow:", ";flex-shrink:", ";align-self:", ";order:", ";margin-", ":", ";width:", ";max-width:", ";text-align:", ";"], flexBasis, flexGrow, size && 0, alignSelf === 'start' || alignSelf === 'end' ? "flex-".concat(alignSelf) : alignSelf, flexOrder, props.theme.rtl ? 'right' : 'left', margin, width, maxWidth, horizontalAlign);
};
var mediaStyles$1 = function mediaStyles(minWidth, size, alignSelf, textAlign, offset, order, props) {
  return css(["@media (min-width:", "){", ";}"], minWidth, flexStyles$1(size, alignSelf, textAlign, offset, order, props));
};
var sizeStyles$2 = function sizeStyles(props) {
  var padding = props.gutters ? math("".concat(props.theme.space[props.gutters], " / 2")) : 0;
  return css(["padding-right:", ";padding-left:", ";"], padding, padding);
};
var StyledCol = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$2,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledCol",
  componentId: "sc-inuw62-0"
})(["box-sizing:border-box;width:100%;", ";", ";", ";", ";", ";", ";", ";", ";", ";"], function (props) {
  return flexStyles$1(!props.sizeAll && (props.xs || props.sm || props.md || props.lg || props.xl) ? undefined : props.sizeAll || false, props.alignSelf, props.textAlign, props.offset, props.order, props);
}, function (props) {
  return sizeStyles$2(props);
}, function (props) {
  return props.debug && colorStyles$2(props);
}, function (props) {
  return mediaStyles$1(props.theme.breakpoints.xs, props.xs, props.alignSelfXs, props.textAlignXs, props.offsetXs, props.orderXs, props);
}, function (props) {
  return mediaStyles$1(props.theme.breakpoints.sm, props.sm, props.alignSelfSm, props.textAlignSm, props.offsetSm, props.orderSm, props);
}, function (props) {
  return mediaStyles$1(props.theme.breakpoints.md, props.md, props.alignSelfMd, props.textAlignMd, props.offsetMd, props.orderMd, props);
}, function (props) {
  return mediaStyles$1(props.theme.breakpoints.lg, props.lg, props.alignSelfLg, props.textAlignLg, props.offsetLg, props.orderLg, props);
}, function (props) {
  return mediaStyles$1(props.theme.breakpoints.xl, props.xl, props.alignSelfXl, props.textAlignXl, props.offsetXl, props.orderXl, props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$2, props);
});
StyledCol.defaultProps = {
  columns: 12,
  theme: DEFAULT_THEME
};

var COMPONENT_ID$1 = 'grid.grid';
var colorStyles$1 = function colorStyles(props) {
  var borderColor = getColor(props.theme.palette.crimson, 400, props.theme, 0.5);
  var borderWidth = math("".concat(props.theme.borderWidths.sm, " * 2"));
  return css(["box-shadow:-", " 0 0 0 ", ",", " 0 0 0 ", ";"], borderWidth, borderColor, borderWidth, borderColor);
};
var sizeStyles$1 = function sizeStyles(props) {
  var padding = props.gutters ? math("".concat(props.theme.space[props.gutters], " / 2")) : 0;
  return css(["padding-right:", ";padding-left:", ";"], padding, padding);
};
var StyledGrid = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$1,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledGrid",
  componentId: "sc-oxgg5i-0"
})(["direction:", ";margin-right:auto;margin-left:auto;width:100%;box-sizing:border-box;", ";", ";", ";"], function (props) {
  return props.theme.rtl && 'rtl';
}, function (props) {
  return sizeStyles$1(props);
}, function (props) {
  return props.debug && colorStyles$1(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$1, props);
});
StyledGrid.defaultProps = {
  gutters: 'md',
  theme: DEFAULT_THEME
};

var COMPONENT_ID = 'grid.row';
var colorStyles = function colorStyles(props) {
  var borderColor = getColor(props.theme.palette.mint, 600, props.theme, 0.5);
  var borderWidth = props.theme.borderWidths.sm;
  return css(["box-shadow:inset 0 ", " 0 0 ", ",inset 0 -", " 0 0 ", ";"], borderWidth, borderColor, borderWidth, borderColor);
};
var flexStyles = function flexStyles(alignItems, justifyContent, wrap) {
  var flexAlignItems;
  var flexJustifyContent;
  if (alignItems === 'start' || alignItems === 'end') {
    flexAlignItems = "flex-".concat(alignItems);
  } else {
    flexAlignItems = alignItems;
  }
  if (justifyContent === 'start' || justifyContent === 'end') {
    flexJustifyContent = "flex-".concat(justifyContent);
  } else if (justifyContent === 'between' || justifyContent === 'around') {
    flexJustifyContent = "space-".concat(justifyContent);
  } else {
    flexJustifyContent = justifyContent;
  }
  return css(["flex-wrap:", ";align-items:", ";justify-content:", ";"], wrap, flexAlignItems, flexJustifyContent);
};
var mediaStyles = function mediaStyles(minWidth, alignItems, justifyContent, wrap) {
  return css(["@media (min-width:", "){", ";}"], minWidth, flexStyles(alignItems, justifyContent, wrap));
};
var sizeStyles = function sizeStyles(props) {
  var margin = props.gutters ? math("".concat(props.theme.space[props.gutters], " / 2")) : 0;
  return css(["margin-right:-", ";margin-left:-", ";"], margin, margin);
};
var StyledRow = styled.div.attrs({
  'data-garden-id': COMPONENT_ID,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledRow",
  componentId: "sc-xjsdg1-0"
})(["display:flex;box-sizing:border-box;", " ", ";", ";", ";", ";", ";", ";", ";", ";"], function (props) {
  return flexStyles(props.alignItems, props.justifyContent, props.wrapAll);
}, function (props) {
  return sizeStyles(props);
}, function (props) {
  return props.debug && colorStyles(props);
}, function (props) {
  return mediaStyles(props.theme.breakpoints.xs, props.alignItemsXs, props.justifyContentXs, props.wrapXs);
}, function (props) {
  return mediaStyles(props.theme.breakpoints.sm, props.alignItemsSm, props.justifyContentSm, props.wrapSm);
}, function (props) {
  return mediaStyles(props.theme.breakpoints.md, props.alignItemsMd, props.justifyContentMd, props.wrapMd);
}, function (props) {
  return mediaStyles(props.theme.breakpoints.lg, props.alignItemsLg, props.justifyContentLg, props.wrapLg);
}, function (props) {
  return mediaStyles(props.theme.breakpoints.xl, props.alignItemsXl, props.justifyContentXl, props.wrapXl);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID, props);
});
StyledRow.defaultProps = {
  wrapAll: 'wrap',
  theme: DEFAULT_THEME
};

var GridContext = createContext({
  gutters: 'md'
});
var useGridContext = function useGridContext() {
  return useContext(GridContext);
};

var _excluded$2 = ["size"];
var Col = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      props = _objectWithoutProperties(_ref, _excluded$2);
  var _useGridContext = useGridContext(),
      columns = _useGridContext.columns,
      gutters = _useGridContext.gutters,
      debug = _useGridContext.debug;
  return React.createElement(StyledCol, _extends({
    sizeAll: size,
    columns: columns,
    gutters: gutters,
    debug: debug,
    ref: ref
  }, props));
});
Col.displayName = 'Col';
Col.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  alignSelf: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  alignSelfXs: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  alignSelfSm: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  alignSelfMd: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  alignSelfLg: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  alignSelfXl: PropTypes.oneOf(ARRAY_ALIGN_SELF),
  textAlign: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  textAlignXs: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  textAlignSm: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  textAlignMd: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  textAlignLg: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  textAlignXl: PropTypes.oneOf(ARRAY_TEXT_ALIGN),
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetXs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetSm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetXl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderXs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderSm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderXl: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

var _excluded$1 = ["columns", "debug"];
var Grid = React.forwardRef(function (_ref, ref) {
  var columns = _ref.columns,
      debug = _ref.debug,
      props = _objectWithoutProperties(_ref, _excluded$1);
  var value = useMemo(function () {
    return {
      columns: columns,
      gutters: props.gutters,
      debug: debug
    };
  }, [columns, props.gutters, debug]);
  return React.createElement(GridContext.Provider, {
    value: value
  }, React.createElement(StyledGrid, _extends({
    debug: debug,
    ref: ref
  }, props)));
});
Grid.displayName = 'Grid';
Grid.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gutters: PropTypes.oneOf(ARRAY_SPACE),
  debug: PropTypes.bool
};
Grid.defaultProps = {
  columns: 12,
  gutters: 'md'
};

var _excluded = ["wrap"];
var Row = React.forwardRef(function (_ref, ref) {
  var wrap = _ref.wrap,
      props = _objectWithoutProperties(_ref, _excluded);
  var _useGridContext = useGridContext(),
      gutters = _useGridContext.gutters,
      debug = _useGridContext.debug;
  return React.createElement(StyledRow, _extends({
    gutters: gutters,
    debug: debug,
    wrapAll: wrap,
    ref: ref
  }, props));
});
Row.displayName = 'Row';
Row.propTypes = {
  alignItems: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  alignItemsXs: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  alignItemsSm: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  alignItemsMd: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  alignItemsLg: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  alignItemsXl: PropTypes.oneOf(ARRAY_ALIGN_ITEMS),
  justifyContent: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  justifyContentXs: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  justifyContentSm: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  justifyContentMd: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  justifyContentLg: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  justifyContentXl: PropTypes.oneOf(ARRAY_JUSTIFY_CONTENT),
  wrap: PropTypes.oneOf(ARRAY_WRAP),
  wrapXs: PropTypes.oneOf(ARRAY_WRAP),
  wrapSm: PropTypes.oneOf(ARRAY_WRAP),
  wrapMd: PropTypes.oneOf(ARRAY_WRAP),
  wrapLg: PropTypes.oneOf(ARRAY_WRAP),
  wrapXl: PropTypes.oneOf(ARRAY_WRAP)
};

export { ARRAY_ALIGN_ITEMS, ARRAY_ALIGN_SELF, ARRAY_DIRECTION, ARRAY_JUSTIFY_CONTENT, ARRAY_SPACE, ARRAY_TEXT_ALIGN, ARRAY_WRAP, Col, Grid, Row };
