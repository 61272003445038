import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import { CodeBlock } from '@zendeskgarden/react-typography';

import { useEditorContext } from '../contexts';
import { renderTreeCode } from '../../utils';

export function CodeEditor() {
  const [selectedTab, setSelectedTab] = useState('code');
  const { tree, meta, graph } = useEditorContext();

  const code = renderTreeCode(tree, meta.components);
  const json = JSON.stringify(graph.nodes, null, 2);

  return (
    <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
      <TabList>
        <Tab item="code">Code</Tab>
        <Tab item="json">Graph JSON</Tab>
      </TabList>
      <TabPanel item="code">
        <CodeBlock isNumbered>{code}</CodeBlock>
      </TabPanel>
      <TabPanel item="json">
        <CodeBlock isNumbered>{json}</CodeBlock>
      </TabPanel>
    </Tabs>
  );
}
