import React from 'react';
import { Colors, Controls } from 'flume';
import { Anchor, Button, ToggleButton } from '@zendeskgarden/react-buttons';
import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import {
  Field,
  Label,
  Hint,
  Input,
  FileUpload,
  Message,
  FileList,
  File,
  Checkbox,
} from '@zendeskgarden/react-forms';

import { setNodesToStorage } from './editor';

import defaultGraphString from './meta/graphs/grid-row-columns.json';

export function clearDefaultGraphNode() {
  setNodesToStorage('');
}

export function setDefaultGraphNode() {
  // pre-populate default graph on install
  setNodesToStorage(JSON.parse(defaultGraphString));
}

export const ports = [
  {
    type: 'size',
    name: 'size',
    label: 'Size',
    color: Colors.purple,
    controls: [
      Controls.select({
        name: 'size',
        label: 'Size',
        defaultValue: 'medium',
        options: [
          { value: 'small', label: 'Small' },
          { value: 'medium', label: 'Medium' },
          { value: 'large', label: 'Large' },
        ],
      }),
    ],
  },
];

export const nodes = [
  ['Field'],
  ['Label'],
  ['Hint'],
  ['Input'],
  ['FileUpload'],
  ['Message'],
  ['FileList'],
  ['File'],
  ['Checkbox', (ports) => [ports.boolean({ name: 'checked', label: 'Checked' })]],
  [
    'Button',
    (ports) => [
      ports.size(),
      ports.boolean({ name: 'focusInset', label: 'focusInset' }),
      ports.boolean({ name: 'isBasic', label: 'isBasic' }),
      ports.boolean({ name: 'isDanger', label: 'isDanger' }),
      ports.boolean({ name: 'isLink', label: 'isLink' }),
      ports.boolean({ name: 'isNeutral', label: 'isNeutral' }),
      ports.boolean({ name: 'isPill', label: 'isPill' }),
      ports.boolean({ name: 'isPrimary', label: 'isPrimary' }),
      ports.boolean({ name: 'isStretched', label: 'isStretched' }),
    ],
  ],
  [
    'Anchor',
    (ports) => [
      ports.string({ name: 'href', label: 'href' }),
      ports.boolean({ name: 'isDanger', label: 'isDanger' }),
      ports.boolean({ name: 'isExternal', label: 'isExternal' }),
    ],
  ],
  ['Grid', (ports) => [ports.boolean({ name: 'debug', label: 'debug' })]],
  ['Row'],
  ['Col'],
].map(([name, inputs = () => undefined]) => ({
  type: name,
  label: name,
  description: `${name} Component`,
  inputs: (ports) => [ports.node()].concat(inputs(ports) || []),
  outputs: (ports) => [ports.node()],
}));

export const components = {
  Anchor,
  Button,
  ToggleButton,
  Col,
  Grid,
  Row,
  Field,
  Label,
  Hint,
  Input,
  FileUpload,
  Message,
  FileList,
  File,
  Checkbox,
};

export function resolvePort(portType, data) {
  switch (portType) {
    case 'size':
      return data.size;
    default: {
      return data;
    }
  }
}

export function resolveNode(node, inputValues, nodeType, context) {
  const { node: children, ...props } = inputValues;

  // TODO: remove node rendering logic into renderer
  // TODO: abstract render tree + logic into serializable struct, use to generate code or render
  if (node.type in context.components) {
    let _children = children;
    if (Array.isArray(children)) {
      _children = React.Children.toArray(_children.filter(React.isValidElement));
    } else if (!React.isValidElement(_children) && typeof _children !== 'string') {
      _children = null;
    }
    return {
      node: React.createElement(context.components[node.type], props, _children),
    };
  }

  return inputValues;
}
