/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { Children, useRef, useMemo, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getColor, retrieveComponentStyles, DEFAULT_THEME, isRtl, getLineHeight } from '@zendeskgarden/react-theming';
import { math } from 'polished';
import Highlight, { Prism } from 'prism-react-renderer';
import { useScrollRegion } from '@zendeskgarden/container-scrollregion';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

var COMPONENT_ID$9 = 'typography.blockquote';
var StyledBlockquote = styled.blockquote.attrs({
  'data-garden-id': COMPONENT_ID$9,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledBlockquote",
  componentId: "sc-1tt3ye0-0"
})(["margin:0;border-", ":", " solid;border-color:", ";padding:0;padding-", ":", "px;direction:", ";p + &,& + &{margin-top:", ";}", ";"], function (props) {
  return props.theme.rtl ? 'right' : 'left';
}, function (props) {
  return props.theme.shadowWidths.sm;
}, function (props) {
  return getColor('neutralHue', 400, props.theme);
}, function (props) {
  return props.theme.rtl ? 'right' : 'left';
}, function (props) {
  return props.theme.space.base * 4;
}, function (props) {
  return props.theme.rtl ? 'rtl' : 'ltr';
}, function (props) {
  return props.theme.lineHeights[props.size];
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$9, props);
});
StyledBlockquote.defaultProps = {
  theme: DEFAULT_THEME,
  size: 'md'
};

var COMPONENT_ID$8 = 'typography.font';
var fontStyles = function fontStyles(props) {
  var monospace = props.isMonospace && ['sm', 'md', 'lg', 'inherit'].indexOf(props.size) !== -1;
  var fontFamily = monospace && props.theme.fonts.mono;
  var direction = isRtl(props) ? 'rtl' : 'ltr';
  var fontSize;
  var fontWeight;
  var lineHeight;
  var color;
  if (monospace) {
    if (props.size === 'inherit') {
      fontSize = 'calc(1em - 1px)';
      lineHeight = 'normal';
    } else {
      fontSize = math("".concat(props.theme.fontSizes[props.size], " - 1px"));
      lineHeight = math("".concat(props.theme.lineHeights[props.size], " - 1px"));
    }
  } else if (props.size !== 'inherit') {
    fontSize = props.theme.fontSizes[props.size];
    lineHeight = props.theme.lineHeights[props.size];
  }
  if (props.isBold === true) {
    fontWeight = props.theme.fontWeights.semibold;
  } else if (props.isBold === false || props.size !== 'inherit') {
    fontWeight = props.theme.fontWeights.regular;
  }
  if (props.hue) {
    var shade = props.hue === 'yellow' ? 700 : 600;
    color = getColor(props.hue, shade, props.theme);
  }
  return css(["line-height:", ";color:", ";font-family:", ";font-size:", ";font-weight:", ";direction:", ";"], lineHeight, color, fontFamily, fontSize, fontWeight, direction);
};
var StyledFont = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$8,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledFont",
  componentId: "sc-1iildbo-0"
})(["", ";", ";"], function (props) {
  return fontStyles(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$8, props);
});
StyledFont.defaultProps = {
  theme: DEFAULT_THEME,
  size: 'inherit'
};

var COMPONENT_ID$7 = 'typography.code';
var colorStyles$3 = function colorStyles(props) {
  var hue = props.hue || 'neutralHue';
  var backgroundColor = getColor(hue, 200, props.theme);
  var shade = hue === 'yellow' ? 800 : 700;
  var foregroundColor = getColor(hue, shade, props.theme);
  return css(["background-color:", ";color:", ";a &{color:inherit;}"], backgroundColor, foregroundColor);
};
var StyledCode = styled(StyledFont).attrs({
  'data-garden-id': COMPONENT_ID$7,
  'data-garden-version': '8.47.2',
  as: 'code'
}).withConfig({
  displayName: "StyledCode",
  componentId: "sc-l8yvmf-0"
})(["border-radius:", ";padding:1.5px;", ";", ";"], function (props) {
  return props.theme.borderRadii.sm;
}, function (props) {
  return colorStyles$3(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$7, props);
});
StyledCode.defaultProps = {
  theme: DEFAULT_THEME,
  isMonospace: true,
  hue: 'neutralHue',
  size: 'inherit'
};

var COMPONENT_ID$6 = 'typography.codeblock';
var colorStyles$2 = function colorStyles(props) {
  var backgroundColor = getColor('neutralHue', props.isLight ? 100 : 1000, props.theme);
  var foregroundColor = props.isLight ? props.theme.colors.foreground : getColor('neutralHue', 300, props.theme);
  return css(["background-color:", ";color:", ";"], backgroundColor, foregroundColor);
};
var StyledCodeBlock = styled.pre.attrs({
  'data-garden-id': COMPONENT_ID$6,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledCodeBlock",
  componentId: "sc-5wky57-0"
})(["display:table;margin:0;padding:", "px;box-sizing:border-box;width:100%;direction:ltr;white-space:pre;counter-reset:linenumber;", ";", ";"], function (props) {
  return props.theme.space.base * 3;
}, function (props) {
  return colorStyles$2(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$6, props);
});
StyledCodeBlock.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID$5 = 'typography.codeblock_container';
var StyledCodeBlockContainer = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$5,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledCodeBlockContainer",
  componentId: "sc-14zgbfw-0"
})(["overflow:auto;&:focus{outline:none;}&[data-garden-focus-visible]{box-shadow:", ";}", ";"], function (props) {
  return props.theme.shadows.md(getColor('primaryHue', 600, props.theme, 0.35));
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$5, props);
});
StyledCodeBlockContainer.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID$4 = 'typography.codeblock_code';
var colorStyles$1 = function colorStyles(props) {
  var backgroundColor;
  if (props.diff) {
    var hue;
    switch (props.diff) {
      case 'hunk':
        hue = 'royal';
        break;
      case 'add':
        hue = 'lime';
        break;
      case 'delete':
        hue = 'crimson';
        break;
      case 'change':
        hue = 'lemon';
        break;
    }
    backgroundColor = getColor(hue, 400, props.theme, 0.2);
  } else if (props.isHighlighted) {
    var _hue = props.isLight ? props.theme.palette.black : props.theme.palette.white;
    backgroundColor = getColor(_hue, 600, props.theme, 0.1);
  }
  return css(["background-color:", ";"], backgroundColor);
};
var lineNumberStyles = function lineNumberStyles(props) {
  var color = getColor('neutralHue', props.isLight ? 600 : 500, props.theme);
  var padding;
  if (props.language && props.language === 'diff') {
    padding = 0;
  } else if (props.size === 'sm') {
    padding = props.theme.space.base * 4;
  } else if (props.size === 'lg') {
    padding = props.theme.space.base * 7;
  } else {
    padding = props.theme.space.base * 6;
  }
  return "\n    &::before {\n      display: table-cell;\n      padding-right: ".concat(padding, "px;\n      width: 1px;\n      text-align: right;\n      color: ").concat(color, ";\n      content: counter(linenumber);\n      counter-increment: linenumber;\n    }\n  ");
};
var StyledCodeBlockLine = styled(StyledFont).attrs({
  'data-garden-id': COMPONENT_ID$4,
  'data-garden-version': '8.47.2',
  as: 'code',
  isMonospace: true
}).withConfig({
  displayName: "StyledCodeBlockLine",
  componentId: "sc-1goay17-0"
})(["display:table-row;height:", ";direction:ltr;", ";", ";&::after{display:inline-block;width:", "px;content:'';}", ";"], function (props) {
  return props.theme.lineHeights[props.size];
}, function (props) {
  return colorStyles$1(props);
}, function (props) {
  return props.isNumbered && lineNumberStyles(props);
}, function (props) {
  return props.theme.space.base * 3;
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$4, props);
});
StyledCodeBlockLine.defaultProps = {
  size: 'md',
  theme: DEFAULT_THEME
};

var COMPONENT_ID$3 = 'typography.codeblock_token';
var colorStyles = function colorStyles(props) {
  var palette = props.theme.palette;
  var colors = {
    boolean: props.isLight ? palette.royal[600] : palette.azure[400],
    builtin: palette.teal[400],
    comment: props.isLight ? palette.lime[600] : palette.mint[400],
    constant: props.isLight ? palette.azure[400] : palette.blue[500],
    coord: props.isLight ? palette.purple[600] : palette.blue[200],
    deleted: props.isLight ? palette.red[700] : palette.red[200],
    diff: props.isLight ? palette.yellow[800] : palette.yellow[200],
    function: props.isLight ? palette.orange['M600'] : palette.yellow[300],
    inserted: props.isLight ? palette.green[700] : palette.green[200],
    keyword: palette.fuschia['M400'],
    name: props.isLight ? palette.crimson[400] : palette.blue[300],
    number: props.isLight ? palette.green[600] : palette.green[300],
    punctuation: props.isLight ? palette.red[800] : palette.grey[600],
    regex: palette.red[400],
    value: props.isLight ? palette.red[700] : palette.crimson['M400']
  };
  return css(["&.builtin,&.class-name,&.tag:not(.punctuation):not(.attr-name):not(.attr-value):not(.script){color:", ";}&.doctype,&.prolog,&.tag.punctuation:not(.attr-value):not(.script):not(.spread){color:", ";}&.attribute.value,&.attr-value,&.atrule,&.cdata,&.string,&.url.content{color:", ";}&.constant,&.interpolation-punctuation{color:", ";}&.attr-name,&.attr-value.spread,&.environment,&.interpolation,&.parameter,&.property,&.property-access,&.variable{color:", ";}&.parameter.punctuation,&.attr-name + .attr-value.punctuation{color:inherit;}&.regex{color:", ";}&.boolean,&.bold:not(.diff),&.entity,&.important,&.tag:not(.punctuation):not(.attr-name):not(.attr-value):not(.script):not(.class-name){color:", ";}&.number,&.unit{color:", ";}&.assign-left,&.function,&.selector:not(.attribute){color:", ";}&.atrule.rule,&.keyword{color:", ";}&.blockquote,&.comment,&.shebang{color:", ";}", ".language-css &&.plain{color:", ";}", ".language-diff &&.coord{color:", ";}", ".language-diff &&.deleted{color:", ";}", ".language-diff &&.diff{color:", ";}", ".language-diff &&.inserted{color:", ";}"], colors.builtin, colors.punctuation, colors.value, colors.constant, colors.name, colors.regex, colors.boolean, colors.number, colors.function, colors.keyword, colors.comment, StyledCodeBlock, colors.value, StyledCodeBlock, colors.coord, StyledCodeBlock, colors.deleted, StyledCodeBlock, colors.diff, StyledCodeBlock, colors.inserted);
};
var StyledCodeBlockToken = styled.span.attrs({
  'data-garden-id': COMPONENT_ID$3,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledCodeBlockToken",
  componentId: "sc-1hkshdq-0"
})(["display:inline-block;&.bold:not(.diff){font-weight:", ";}&.coord{padding-left:0.75em;}&.italic{font-style:italic;}&.prefix{width:2em;text-align:center;}", ";", ";"], function (props) {
  return props.theme.fontWeights.semibold;
}, function (props) {
  return colorStyles(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$3, props);
});
StyledCodeBlockToken.defaultProps = {
  theme: DEFAULT_THEME
};

var COMPONENT_ID$2 = 'typography.ellipsis';
var StyledEllipsis = styled.div.attrs({
  'data-garden-id': COMPONENT_ID$2,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledEllipsis",
  componentId: "sc-1u4uqmy-0"
})(["overflow:hidden;text-overflow:ellipsis;white-space:nowrap;direction:", ";", ";"], function (props) {
  return isRtl(props) ? 'rtl' : 'ltr';
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$2, props);
});
StyledEllipsis.defaultProps = {
  theme: DEFAULT_THEME
};

var _excluded$e = ["children", "isStart"];
var COMPONENT_ID$1 = 'typography.icon';
var sizeStyles = function sizeStyles(props) {
  var margin = props.isStart && "".concat(props.theme.space.base * 2, "px");
  var size = props.theme.iconSizes.md;
  return css(["margin-", ":", ";width:", ";height:", ";"], props.theme.rtl ? 'left' : 'right', margin, size, size);
};
var StyledIcon = styled(function (_ref) {
  var children = _ref.children;
      _ref.isStart;
      var props = _objectWithoutProperties(_ref, _excluded$e);
  return React.cloneElement(Children.only(children), props);
}).attrs({
  'data-garden-id': COMPONENT_ID$1,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledIcon",
  componentId: "sc-10rfb5b-0"
})(["position:relative;top:-1px;vertical-align:middle;", ";", ";"], function (props) {
  return sizeStyles(props);
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID$1, props);
});
StyledIcon.defaultProps = {
  theme: DEFAULT_THEME
};

var listStyles = function listStyles(props) {
  var rtl = isRtl(props);
  return css(["direction:", ";margin:0;margin-", ":24px;padding:0;list-style-position:outside;list-style-type:", ";"], rtl ? 'rtl' : 'ltr', rtl ? 'right' : 'left', props.listType);
};
var ORDERED_ID$1 = 'typography.ordered_list';
var StyledOrderedList = styled.ol.attrs({
  'data-garden-id': ORDERED_ID$1,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledList__StyledOrderedList",
  componentId: "sc-jdbsfi-0"
})(["", ";", ";"], function (props) {
  return listStyles(props);
}, function (props) {
  return retrieveComponentStyles(ORDERED_ID$1, props);
});
StyledOrderedList.defaultProps = {
  theme: DEFAULT_THEME
};
var UNORDERED_ID$1 = 'typography.unordered_list';
var StyledUnorderedList = styled.ul.attrs({
  'data-garden-id': UNORDERED_ID$1,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledList__StyledUnorderedList",
  componentId: "sc-jdbsfi-1"
})(["", ";", ";"], function (props) {
  return listStyles(props);
}, function (props) {
  return retrieveComponentStyles(UNORDERED_ID$1, props);
});
StyledUnorderedList.defaultProps = {
  theme: DEFAULT_THEME
};

var listItemPaddingStyles = function listItemPaddingStyles(props) {
  var base = props.theme.space.base;
  var paddingTop = props.space === 'large' ? "".concat(base * 2, "px") : "".concat(base, "px");
  return css(["padding-top:", ";", " > &:first-child,", " > &:first-child{padding-top:0;}", " ", " > &:first-child,", " ", " > &:first-child,", " ", " > &:first-child,", " ", " > &:first-child{padding-top:", ";}"], paddingTop, StyledOrderedList, StyledUnorderedList, StyledOrderedList, StyledOrderedList, StyledOrderedList, StyledUnorderedList, StyledUnorderedList, StyledUnorderedList, StyledUnorderedList, StyledUnorderedList, paddingTop);
};
var listItemStyles = function listItemStyles(props) {
  return css(["line-height:", ";", ";"], getLineHeight(props.theme.lineHeights.md, props.theme.fontSizes.md), props.space !== 'small' && listItemPaddingStyles(props));
};
var ORDERED_ID = 'typography.ordered_list_item';
var StyledOrderedListItem = styled(StyledFont).attrs({
  'data-garden-id': ORDERED_ID,
  'data-garden-version': '8.47.2',
  as: 'li'
}).withConfig({
  displayName: "StyledListItem__StyledOrderedListItem",
  componentId: "sc-9rsipg-0"
})(["margin-", ":", ";padding-", ":", ";", ";", ";"], function (props) {
  return isRtl(props) ? 'right' : 'left';
}, function (props) {
  return math("".concat(props.theme.space.base, " * -1px"));
}, function (props) {
  return isRtl(props) ? 'right' : 'left';
}, function (props) {
  return math("".concat(props.theme.space.base, " * 1px"));
}, function (props) {
  return listItemStyles(props);
}, function (props) {
  return retrieveComponentStyles(ORDERED_ID, props);
});
StyledOrderedListItem.defaultProps = {
  space: 'medium',
  theme: DEFAULT_THEME
};
var UNORDERED_ID = 'typography.unordered_list_item';
var StyledUnorderedListItem = styled(StyledFont).attrs({
  'data-garden-id': UNORDERED_ID,
  'data-garden-version': '8.47.2',
  as: 'li'
}).withConfig({
  displayName: "StyledListItem__StyledUnorderedListItem",
  componentId: "sc-9rsipg-1"
})(["", ";", ";"], function (props) {
  return listItemStyles(props);
}, function (props) {
  return retrieveComponentStyles(UNORDERED_ID, props);
});
StyledUnorderedListItem.defaultProps = {
  space: 'medium',
  theme: DEFAULT_THEME
};

var COMPONENT_ID = 'typography.paragraph';
var StyledParagraph = styled.p.attrs({
  'data-garden-id': COMPONENT_ID,
  'data-garden-version': '8.47.2'
}).withConfig({
  displayName: "StyledParagraph",
  componentId: "sc-zkuftz-0"
})(["margin:0;padding:0;direction:", ";blockquote + &,& + &{margin-top:", ";}", ";"], function (props) {
  return props.theme.rtl ? 'rtl' : 'ltr';
}, function (props) {
  return props.theme.lineHeights[props.size];
}, function (props) {
  return retrieveComponentStyles(COMPONENT_ID, props);
});
StyledParagraph.defaultProps = {
  theme: DEFAULT_THEME,
  size: 'md'
};

var _excluded$d = ["tag"];
var SM = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$d);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "sm"
  }, other));
});
SM.displayName = 'SM';
SM.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool,
  isMonospace: PropTypes.bool
};
SM.defaultProps = {
  tag: 'div'
};

var _excluded$c = ["tag"];
var MD = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$c);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "md"
  }, other));
});
MD.displayName = 'MD';
MD.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool,
  isMonospace: PropTypes.bool
};
MD.defaultProps = {
  tag: 'div'
};

var _excluded$b = ["tag"];
var LG = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$b);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "lg"
  }, other));
});
LG.displayName = 'LG';
LG.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool,
  isMonospace: PropTypes.bool
};
LG.defaultProps = {
  tag: 'div'
};

var _excluded$a = ["tag"];
var XL = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$a);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "xl"
  }, other));
});
XL.displayName = 'XL';
XL.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool
};
XL.defaultProps = {
  tag: 'div'
};

var _excluded$9 = ["tag"];
var XXL = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$9);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "xxl"
  }, other));
});
XXL.displayName = 'XXL';
XXL.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool
};
XXL.defaultProps = {
  tag: 'div'
};

var _excluded$8 = ["tag"];
var XXXL = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$8);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "xxxl"
  }, other));
});
XXXL.displayName = 'XXXL';
XXXL.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool
};
XXXL.defaultProps = {
  tag: 'div'
};

var _excluded$7 = ["size", "hue"];
var Code = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      hue = _ref.hue,
      other = _objectWithoutProperties(_ref, _excluded$7);
  var _size;
  if (size === 'small') {
    _size = 'sm';
  } else if (size === 'medium') {
    _size = 'md';
  } else if (size === 'large') {
    _size = 'lg';
  } else {
    _size = 'inherit';
  }
  return React.createElement(StyledCode, _extends({
    ref: ref,
    size: _size,
    hue: hue
  }, other));
});
Code.displayName = 'Code';
Code.propTypes = {
  hue: PropTypes.oneOf(['grey', 'red', 'green', 'yellow']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'inherit'])
};
Code.defaultProps = {
  hue: 'grey',
  size: 'inherit'
};

var _excluded$6 = ["size"];
var Blockquote = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      other = _objectWithoutProperties(_ref, _excluded$6);
  var _size;
  if (size === 'small') {
    _size = 'sm';
  } else if (size === 'medium') {
    _size = 'md';
  } else {
    _size = 'lg';
  }
  return React.createElement(StyledBlockquote, _extends({
    ref: ref,
    size: _size
  }, other));
});
Blockquote.displayName = 'Blockquote';
Blockquote.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};
Blockquote.defaultProps = {
  size: 'medium'
};

var _excluded$5 = ["children", "containerProps", "highlightLines", "isLight", "isNumbered", "language", "size"];
var LANGUAGES = ['markup', 'bash', 'clike', 'c', 'cpp', 'css', 'javascript', 'jsx', 'coffeescript', 'actionscript', 'css-extr', 'diff', 'git', 'go', 'graphql', 'handlebars', 'json', 'less', 'makefile', 'markdown', 'objectivec', 'ocaml', 'python', 'reason', 'sass', 'scss', 'sql', 'stylus', 'tsx', 'typescript', 'wasm', 'yaml'];
var CodeBlock = React.forwardRef(function (_ref, ref) {
  var children = _ref.children,
      containerProps = _ref.containerProps,
      highlightLines = _ref.highlightLines,
      isLight = _ref.isLight,
      isNumbered = _ref.isNumbered,
      language = _ref.language,
      size = _ref.size,
      other = _objectWithoutProperties(_ref, _excluded$5);
  var containerRef = useRef(null);
  var code = Array.isArray(children) ? children[0] : children;
  var SIZES = {
    small: 'sm',
    medium: 'md',
    large: 'lg'
  };
  var dependency = useMemo(function () {
    return [size, children];
  }, [size, children]);
  var containerTabIndex = useScrollRegion({
    containerRef: containerRef,
    dependency: dependency
  });
  var getDiff = function getDiff(line) {
    var retVal;
    if (language === 'diff') {
      var token = line.find(function (value) {
        return !(value.empty || value.content === '');
      });
      if (token) {
        if (token.types.includes('deleted')) {
          retVal = 'delete';
        } else if (token.types.includes('inserted')) {
          retVal = 'add';
        } else if (token.types.includes('coord')) {
          retVal = 'hunk';
        } else if (token.types.includes('diff')) {
          retVal = 'change';
        }
      }
    }
    return retVal;
  };
  return React.createElement(StyledCodeBlockContainer, _extends({}, containerProps, {
    ref: containerRef,
    tabIndex: containerTabIndex
  }), React.createElement(Highlight, {
    Prism: Prism,
    code: code ? code.trim() : '',
    language: LANGUAGES.includes(language) ? language : 'tsx'
  }, function (_ref2) {
    var className = _ref2.className,
        tokens = _ref2.tokens,
        getLineProps = _ref2.getLineProps,
        getTokenProps = _ref2.getTokenProps;
    return React.createElement(StyledCodeBlock, _extends({
      className: className,
      ref: ref,
      isLight: isLight
    }, other), tokens.map(function (line, index) {
      return React.createElement(StyledCodeBlockLine, _extends({}, getLineProps({
        line: line
      }), {
        key: index,
        language: language,
        isHighlighted: highlightLines && highlightLines.includes(index + 1),
        isLight: isLight,
        isNumbered: isNumbered,
        diff: getDiff(line),
        size: size ? SIZES[size] : undefined
      }), line.map(function (token, tokenKey) {
        return React.createElement(StyledCodeBlockToken, _extends({}, getTokenProps({
          token: token
        }), {
          key: tokenKey,
          isLight: isLight
        }), token.empty ? '\n' : token.content);
      }));
    }));
  }));
});
CodeBlock.displayName = 'CodeBlock';
CodeBlock.defaultProps = {
  language: 'tsx',
  size: 'medium'
};

var _excluded$4 = ["tag"];
var Span = React.forwardRef(function (_ref, ref) {
  var tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$4);
  return React.createElement(StyledFont, _extends({
    as: tag,
    ref: ref,
    size: "inherit"
  }, other));
});
Span.displayName = 'Span';
Span.propTypes = {
  tag: PropTypes.any,
  isBold: PropTypes.bool,
  isMonospace: PropTypes.bool,
  hue: PropTypes.string
};
Span.defaultProps = {
  tag: 'span'
};
var StartIcon = function StartIcon(props) {
  return React.createElement(StyledIcon, _extends({
    isStart: true
  }, props));
};
var Icon = function Icon(props) {
  return React.createElement(StyledIcon, props);
};
Span.StartIcon = StartIcon;
Span.Icon = Icon;

var _excluded$3 = ["children", "title", "tag"];
var Ellipsis = React.forwardRef(function (_ref, ref) {
  var children = _ref.children,
      title = _ref.title,
      tag = _ref.tag,
      other = _objectWithoutProperties(_ref, _excluded$3);
  var textContent = undefined;
  if (title !== undefined) {
    textContent = title;
  } else if (typeof children === 'string') {
    textContent = children;
  }
  return React.createElement(StyledEllipsis, _extends({
    as: tag,
    ref: ref,
    title: textContent
  }, other), children);
});
Ellipsis.displayName = 'Ellipsis';
Ellipsis.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.any
};
Ellipsis.defaultProps = {
  tag: 'div'
};

var _excluded$2 = ["size"];
var Paragraph = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      other = _objectWithoutProperties(_ref, _excluded$2);
  var _size;
  if (size === 'small') {
    _size = 'sm';
  } else if (size === 'medium') {
    _size = 'md';
  } else {
    _size = 'lg';
  }
  return React.createElement(StyledParagraph, _extends({
    ref: ref,
    size: _size
  }, other));
});
Paragraph.displayName = 'Paragraph';
Paragraph.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};
Paragraph.defaultProps = {
  size: 'medium'
};

var OrderedListContext = createContext(undefined);
var useOrderedListContext = function useOrderedListContext() {
  var listContext = useContext(OrderedListContext);
  if (!listContext) {
    throw new Error('This component must be rendered within an `OrderedList` component.');
  }
  return listContext;
};

var OrderedListItem = React.forwardRef(function (props, ref) {
  var _useOrderedListContex = useOrderedListContext(),
      size = _useOrderedListContex.size;
  return React.createElement(StyledOrderedListItem, _extends({
    ref: ref,
    space: size
  }, props));
});
OrderedListItem.displayName = 'OrderedListItem';

var _excluded$1 = ["size", "type"];
var OrderedList = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      type = _ref.type,
      other = _objectWithoutProperties(_ref, _excluded$1);
  var value = useMemo(function () {
    return {
      size: size
    };
  }, [size]);
  return React.createElement(OrderedListContext.Provider, {
    value: value
  }, React.createElement(StyledOrderedList, _extends({
    ref: ref,
    listType: type
  }, other)));
});
OrderedList.displayName = 'OrderedList';
OrderedList.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['decimal', 'decimal-leading-zero', 'lower-alpha', 'lower-roman', 'upper-alpha', 'upper-roman'])
};
OrderedList.defaultProps = {
  size: 'medium',
  type: 'decimal'
};
OrderedList.Item = OrderedListItem;

var UnorderedListContext = createContext(undefined);
var useUnorderedListContext = function useUnorderedListContext() {
  var listContext = useContext(UnorderedListContext);
  if (!listContext) {
    throw new Error('This component must be rendered within an `UnorderedList` component.');
  }
  return listContext;
};

var UnorderedListItem = React.forwardRef(function (props, ref) {
  var _useUnorderedListCont = useUnorderedListContext(),
      size = _useUnorderedListCont.size;
  return React.createElement(StyledUnorderedListItem, _extends({
    ref: ref,
    space: size
  }, props));
});
UnorderedListItem.displayName = 'UnorderedListItem';

var _excluded = ["size", "type"];
var UnorderedList = React.forwardRef(function (_ref, ref) {
  var size = _ref.size,
      type = _ref.type,
      other = _objectWithoutProperties(_ref, _excluded);
  var value = useMemo(function () {
    return {
      size: size
    };
  }, [size]);
  return React.createElement(UnorderedListContext.Provider, {
    value: value
  }, React.createElement(StyledUnorderedList, _extends({
    ref: ref,
    listType: type
  }, other)));
});
UnorderedList.displayName = 'UnorderedList';
UnorderedList.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['circle', 'disc', 'square'])
};
UnorderedList.defaultProps = {
  size: 'medium',
  type: 'disc'
};
UnorderedList.Item = UnorderedListItem;

export { Blockquote, Code, CodeBlock, Ellipsis, LG, MD, OrderedList, Paragraph, SM, Span, UnorderedList, XL, XXL, XXXL };
