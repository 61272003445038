/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

var INPUT_TYPES_WHITE_LIST = {
  text: true,
  search: true,
  url: true,
  tel: true,
  email: true,
  password: true,
  number: true,
  date: true,
  month: true,
  week: true,
  time: true,
  datetime: true,
  'datetime-local': true
};
function useFocusVisible(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      scope = _ref.scope,
      relativeDocument = _ref.relativeDocument,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? 'garden-focus-visible' : _ref$className,
      _ref$dataAttribute = _ref.dataAttribute,
      dataAttribute = _ref$dataAttribute === void 0 ? 'data-garden-focus-visible' : _ref$dataAttribute;
  if (!scope) {
    throw new Error('Error: the useFocusVisible() hook requires a "scope" property');
  }
  var hadKeyboardEvent = useRef(false);
  var hadFocusVisibleRecently = useRef(false);
  var hadFocusVisibleRecentlyTimeout = useRef();
  useEffect(function () {
    var environment = relativeDocument;
    if (!environment) {
      environment = document;
    }
    var isValidFocusTarget = function isValidFocusTarget(el) {
      if (el && el !== scope.current && el.nodeName !== 'HTML' && el.nodeName !== 'BODY' && 'classList' in el && 'contains' in el.classList) {
        return true;
      }
      return false;
    };
    var focusTriggersKeyboardModality = function focusTriggersKeyboardModality(el) {
      var type = el.type;
      var tagName = el.tagName;
      if (tagName === 'INPUT' && INPUT_TYPES_WHITE_LIST[type] && !el.readOnly) {
        return true;
      }
      if (tagName === 'TEXTAREA' && !el.readOnly) {
        return true;
      }
      if (el.isContentEditable) {
        return true;
      }
      return false;
    };
    var isFocused = function isFocused(el) {
      if (el && (el.classList.contains(className) || el.hasAttribute(dataAttribute))) {
        return true;
      }
      return false;
    };
    var addFocusVisibleClass = function addFocusVisibleClass(el) {
      if (isFocused(el)) {
        return;
      }
      el && el.classList.add(className);
      el && el.setAttribute(dataAttribute, 'true');
    };
    var removeFocusVisibleClass = function removeFocusVisibleClass(el) {
      el.classList.remove(className);
      el.removeAttribute(dataAttribute);
    };
    var onKeyDown = function onKeyDown(e) {
      if (e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }
      if (isValidFocusTarget(environment.activeElement)) {
        addFocusVisibleClass(environment.activeElement);
      }
      hadKeyboardEvent.current = true;
    };
    var onPointerDown = function onPointerDown() {
      hadKeyboardEvent.current = false;
    };
    var onFocus = function onFocus(e) {
      if (!isValidFocusTarget(e.target)) {
        return;
      }
      if (hadKeyboardEvent.current || focusTriggersKeyboardModality(e.target)) {
        addFocusVisibleClass(e.target);
      }
    };
    var onBlur = function onBlur(e) {
      if (!isValidFocusTarget(e.target)) {
        return;
      }
      if (isFocused(e.target)) {
        hadFocusVisibleRecently.current = true;
        clearTimeout(hadFocusVisibleRecentlyTimeout.current);
        var timeoutId = setTimeout(function () {
          hadFocusVisibleRecently.current = false;
          clearTimeout(hadFocusVisibleRecentlyTimeout.current);
        }, 100);
        hadFocusVisibleRecentlyTimeout.current = Number(timeoutId);
        removeFocusVisibleClass(e.target);
      }
    };
    var onInitialPointerMove = function onInitialPointerMove(e) {
      var nodeName = e.target.nodeName;
      if (nodeName && nodeName.toLowerCase() === 'html') {
        return;
      }
      hadKeyboardEvent.current = false;
      removeInitialPointerMoveListeners();
    };
    var addInitialPointerMoveListeners = function addInitialPointerMoveListeners() {
      environment.addEventListener('mousemove', onInitialPointerMove);
      environment.addEventListener('mousedown', onInitialPointerMove);
      environment.addEventListener('mouseup', onInitialPointerMove);
      environment.addEventListener('pointermove', onInitialPointerMove);
      environment.addEventListener('pointerdown', onInitialPointerMove);
      environment.addEventListener('pointerup', onInitialPointerMove);
      environment.addEventListener('touchmove', onInitialPointerMove);
      environment.addEventListener('touchstart', onInitialPointerMove);
      environment.addEventListener('touchend', onInitialPointerMove);
    };
    var removeInitialPointerMoveListeners = function removeInitialPointerMoveListeners() {
      environment.removeEventListener('mousemove', onInitialPointerMove);
      environment.removeEventListener('mousedown', onInitialPointerMove);
      environment.removeEventListener('mouseup', onInitialPointerMove);
      environment.removeEventListener('pointermove', onInitialPointerMove);
      environment.removeEventListener('pointerdown', onInitialPointerMove);
      environment.removeEventListener('pointerup', onInitialPointerMove);
      environment.removeEventListener('touchmove', onInitialPointerMove);
      environment.removeEventListener('touchstart', onInitialPointerMove);
      environment.removeEventListener('touchend', onInitialPointerMove);
    };
    var onVisibilityChange = function onVisibilityChange() {
      if (environment.visibilityState === 'hidden') {
        if (hadFocusVisibleRecently.current) {
          hadKeyboardEvent.current = true;
        }
      }
    };
    var currentScope = scope.current;
    if (!environment || !currentScope) {
      return;
    }
    environment.addEventListener('keydown', onKeyDown, true);
    environment.addEventListener('mousedown', onPointerDown, true);
    environment.addEventListener('pointerdown', onPointerDown, true);
    environment.addEventListener('touchstart', onPointerDown, true);
    environment.addEventListener('visibilitychange', onVisibilityChange, true);
    addInitialPointerMoveListeners();
    currentScope && currentScope.addEventListener('focus', onFocus, true);
    currentScope && currentScope.addEventListener('blur', onBlur, true);
    return function () {
      environment.removeEventListener('keydown', onKeyDown);
      environment.removeEventListener('mousedown', onPointerDown);
      environment.removeEventListener('pointerdown', onPointerDown);
      environment.removeEventListener('touchstart', onPointerDown);
      environment.removeEventListener('visibilityChange', onVisibilityChange);
      removeInitialPointerMoveListeners();
      currentScope && currentScope.removeEventListener('focus', onFocus);
      currentScope && currentScope.removeEventListener('blur', onBlur);
      clearTimeout(hadFocusVisibleRecentlyTimeout.current);
    };
  }, [relativeDocument, scope, className, dataAttribute]);
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded = ["children", "render"];
var FocusVisibleContainer = function FocusVisibleContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
  var scopeRef = useRef(null);
  useFocusVisible(_extends({
    scope: scopeRef
  }, options));
  return React.createElement(React.Fragment, null, render({
    ref: scopeRef
  }));
};
FocusVisibleContainer.defaultProps = {
  className: 'garden-focus-visible',
  dataAttribute: 'data-garden-focus-visible'
};
FocusVisibleContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  relativeDocument: PropTypes.object,
  className: PropTypes.string,
  dataAttribute: PropTypes.string
};

export { FocusVisibleContainer, useFocusVisible };
