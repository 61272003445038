/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useState } from 'react';
import { useUIDSeed } from 'react-uid';
import { useSelection } from '@zendeskgarden/container-selection';
import PropTypes from 'prop-types';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded$1 = ["vertical", "idPrefix"],
    _excluded2 = ["role"],
    _excluded3 = ["role", "index"],
    _excluded4 = ["role", "index", "item"];
function requiredArguments(arg, argStr, methodName) {
  if (typeof arg === 'undefined' || arg === null) {
    throw new Error("Accessibility Error: You must provide an \"" + argStr + "\" option to \"" + methodName + "()\"");
  }
}
function useTabs(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      vertical = _ref.vertical,
      idPrefix = _ref.idPrefix,
      options = _objectWithoutPropertiesLoose(_ref, _excluded$1);
  var _useSelection = useSelection(_extends({
    direction: vertical ? 'vertical' : 'horizontal',
    defaultSelectedIndex: 0
  }, options)),
      selectedItem = _useSelection.selectedItem,
      focusedItem = _useSelection.focusedItem,
      getContainerProps = _useSelection.getContainerProps,
      getItemProps = _useSelection.getItemProps;
  var seed = useUIDSeed();
  var _useState = useState(idPrefix || seed("tabs_" + '0.5.15')),
      _id = _useState[0];
  var PANEL_ID = _id + "--panel";
  var TAB_ID = _id + "--tab";
  var _getTabListProps = function getTabListProps(_temp2) {
    var _ref2 = _temp2 === void 0 ? {} : _temp2,
        _ref2$role = _ref2.role,
        role = _ref2$role === void 0 ? 'tablist' : _ref2$role,
        other = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return _extends({
      role: role,
      'aria-orientation': vertical ? 'vertical' : 'horizontal',
      'data-garden-container-id': 'containers.tabs',
      'data-garden-container-version': '0.5.15'
    }, other);
  };
  var _getTabProps = function getTabProps(_temp3) {
    var _ref3 = _temp3 === void 0 ? {} : _temp3,
        _ref3$role = _ref3.role,
        role = _ref3$role === void 0 ? 'tab' : _ref3$role,
        index = _ref3.index,
        other = _objectWithoutPropertiesLoose(_ref3, _excluded3);
    requiredArguments(index, 'index', 'getTabProps');
    return _extends({
      id: TAB_ID + ":" + index,
      'aria-controls': PANEL_ID + ":" + index,
      role: role
    }, other);
  };
  var getTabPanelProps = function getTabPanelProps(_temp4) {
    var _ref4 = _temp4 === void 0 ? {} : _temp4,
        _ref4$role = _ref4.role,
        role = _ref4$role === void 0 ? 'tabpanel' : _ref4$role,
        index = _ref4.index,
        item = _ref4.item,
        other = _objectWithoutPropertiesLoose(_ref4, _excluded4);
    requiredArguments(index, 'index', 'getTabPanelProps');
    requiredArguments(item, 'item', 'getTabPanelProps');
    var isHidden = item !== selectedItem;
    return _extends({
      role: role,
      id: PANEL_ID + ":" + index,
      hidden: isHidden,
      'aria-labelledby': TAB_ID + ":" + index
    }, other);
  };
  return {
    selectedItem: selectedItem,
    focusedItem: focusedItem,
    getTabPanelProps: getTabPanelProps,
    getTabListProps: function getTabListProps(props) {
      return getContainerProps(_getTabListProps(props));
    },
    getTabProps: function getTabProps(props) {
      return getItemProps(_getTabProps(props), 'getTabProps');
    }
  };
}

var _excluded = ["children", "render"];
var TabsContainer = function TabsContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
  return React.createElement(React.Fragment, null, render(useTabs(options)));
};
TabsContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  vertical: PropTypes.bool,
  idPrefix: PropTypes.string,
  defaultSelectedIndex: PropTypes.number,
  onSelect: PropTypes.func
};

export { TabsContainer, useTabs };
