export function findFlumeRootNode(nodes = {}) {
  const nodesArray = Object.values(nodes);
  return nodesArray.find((node) => node.root);
}

export function extractInputsFromNode(node) {
  return Object.values(node.connections.inputs).reduce(
    (inputsArray, nodeInputs = []) => {
      inputsArray.push(...nodeInputs);
      return inputsArray;
    },
    [],
  );
}

export function extractPropsFromNodeInputs(node, inputs, nodes) {
  if (/^(view|Fragment)/.test(node.type)) return null;

  const props = {
    // ...inputs,
  };

  return props;
}

export function extractChildrenFromNodeInputs(node, inputs, nodes) {
  // if (/^(string).*/.test(node.type)) return null;

  return inputs
    .filter((_node) => /^(node|string).*/.test(_node.portName))
    .map((_node) => nodes[_node.nodeId]);
}

export function traverseFlumeNodes(
  nodes = {},
  node = findFlumeRootNode(nodes),
  { graphs = {} } = {},
) {
  if (node) {
    if (node.type === 'Graph') {
      const { inputData: { graph: { graph: { graph } = {} } = {} } = {} } = node;
      return traverseFlumeNodes(graph, undefined, { graphs });
    }

    const inputs = extractInputsFromNode(node);
    const props = extractPropsFromNodeInputs(node, inputs);
    const children = extractChildrenFromNodeInputs(node, inputs, nodes);

    return [node, props, children.map((child) => traverseFlumeNodes(nodes, child))];
  }

  return [];
}
