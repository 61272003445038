import React, { useRef, useEffect } from 'react';

import { IFRAME_PATH } from '../../utils';
import { useEditorContext } from '../contexts';

export function GraphPreview() {
  const {
    graph: { nodes },
  } = useEditorContext();
  const iframeRef = useRef();

  useEffect(() => {
    if (!window.location.pathname.endsWith(IFRAME_PATH) && iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ type: 'update', nodes: nodes });
    }
  }, [nodes, iframeRef]);

  return (
    <iframe
      ref={iframeRef}
      src={`.${IFRAME_PATH}`}
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
}
