import { useMemo } from 'react';

export function useExtensions() {
  // TODO: make configurable when setting up project or available server side when hosted
  return useMemo(() => [
    {
      name: 'Zendesk API',
      description: 'build Zendesk apps that connect to the backend',
    },
    {
      name: 'A/B Testing',
      description:
        'create experimental branches of UI and build conditions, can be tracked with analytics',
    },
    {
      name: 'Analytics',
      description: 'Integrates with popular options or use a basic version',
    },
    {
      name: 'Internationalization',
      description: 'intl for text or modified renderings based on locale/market',
    },
    {
      name: 'Progressive Web App',
      description: 'Adds offline, push notifications, caching, encryption, etc',
    },
    {
      name: 'Data',
      description: 'REST data logic nodes',
    },
    {
      name: 'GraphQL',
      description: 'integration with GraphQL backend',
    },
    {
      name: 'CMS',
      description: 'integration with CMS backend',
    },
    {
      name: 'Deployment (CI/CD)',
      description: 'integration with cloud providers (terraform, runners)',
    },
    {
      name: 'Design',
      description: 'integration with Figma',
    },
    {
      name: 'Icons',
      description: 'set up icon library or integrate with CMS',
    },
    {
      name: 'Components',
      description: 'Future: move design system components into its own extension',
    },
    {
      name: 'Templates',
      description:
        'Makes the editing experience editable for instancing or publishing',
    },
    {
      name: 'Desktop',
      description: 'Make web app publishable beyond the web',
    },
  ]);
}
