import React from 'react';
import { Link, useMatch } from '@reach/router';
import { Nav, NavItem, NavItemIcon, NavItemText } from '@zendeskgarden/react-chrome';
import { PALETTE } from '@zendeskgarden/react-theming';

import { GardenIcon, ZendeskIcon } from './Icons';

// eslint-disable-next-line react/prop-types
export function NavLink({ to, label, children, ...props }) {
  const match = useMatch(to);

  return (
    <Link
      to={to}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <NavItem isCurrent={!!match} style={{ width: '100%' }} {...props}>
        <NavItemIcon>{children}</NavItemIcon>
        <NavItemText>{label}</NavItemText>
      </NavItem>
    </Link>
  );
}

// eslint-disable-next-line react/prop-types
export function NavBar({ children, ...props }) {
  return (
    <Nav {...props}>
      <NavItem hasLogo>
        <NavItemIcon>
          <GardenIcon style={{ color: PALETTE.green[400] }} />
        </NavItemIcon>
        <NavItemText>Zendesk Garden</NavItemText>
      </NavItem>
      {children}
      <NavItem hasBrandmark title="Zendesk">
        <NavItemIcon>
          <ZendeskIcon />
        </NavItemIcon>
        <NavItemText>Zendesk</NavItemText>
      </NavItem>
    </Nav>
  );
}
