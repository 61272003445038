import React from 'react';
import {
  Dropdown,
  Field,
  Menu,
  Item,
  Select,
  Label,
} from '@zendeskgarden/react-dropdowns';

export function GraphSelect(
  data = {},
  onChange,
  context,
  // redraw,
  // portProps,
  // inputData,
) {
  const { graphs } = context;

  return (
    <Dropdown
      selectedItem={data}
      onSelect={onChange}
      downshiftProps={{ itemToString: (item) => item && item.name }}
    >
      <Field>
        <Label>Select Graph</Label>
        <Select>{data.name}</Select>
      </Field>
      <Menu>
        {graphs.map((graph) => (
          <Item key={graph.name} value={graph}>
            {graph.name}
          </Item>
        ))}
      </Menu>
    </Dropdown>
  );
}
