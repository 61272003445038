import { useState, useMemo, useCallback, useRef, cloneElement } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useEditorContext } from '../contexts';

export function LayoutContainer({ children, node }) {
  const [selected, setSelected] = useState(false);
  const {
    editor: {
      layout: { itemTypes },
    },
    addNode,
  } = useEditorContext();
  const ref = useRef();

  console.log(node);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: itemTypes.node,
    item: node,
    collect: (monitor) => ({
      dropped: monitor.didDrop(),
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [itemTypes.node],
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        addNode(item, node);
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      console.log(selected);
      setSelected(true);
    },
    [selected],
  );

  const style = useMemo(() => {
    const styles = {};

    if (isDragging) {
      styles.transform = 'scale(0.8,0.8)';
    }

    if (isOver && canDrop) {
      styles.backgroundColor = 'orange';
    }

    return styles;
  }, [isDragging, canDrop, isOver]);

  return cloneElement(children, {
    ref: drag(drop(ref)),
    onClick,
    style,
  });
}
