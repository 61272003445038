/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

function useScrollRegion(_ref) {
  var containerRef = _ref.containerRef,
      dependency = _ref.dependency;
  var _useState = useState(),
      containerTabIndex = _useState[0],
      setContainerTabIndex = _useState[1];
  var updateContainerTabIndex = useMemo(function () {
    return debounce(function () {
      if (containerRef.current) {
        var regionContent = containerRef.current.children[0];
        var regionContentHeight = regionContent.scrollHeight;
        var regionContentWidth = regionContent.scrollWidth;
        var containerHeight = containerRef.current.offsetHeight;
        var containerWidth = containerRef.current.offsetWidth;
        if (regionContentWidth > containerWidth || regionContentHeight > containerHeight) {
          setContainerTabIndex(0);
        } else {
          setContainerTabIndex(undefined);
        }
      }
    }, 100);
  }, [containerRef, setContainerTabIndex]);
  useEffect(function () {
    addEventListener('resize', updateContainerTabIndex);
    updateContainerTabIndex();
    return function () {
      removeEventListener('resize', updateContainerTabIndex);
      updateContainerTabIndex.cancel();
    };
  }, [updateContainerTabIndex, dependency]);
  return containerTabIndex;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded = ["children", "render"];
var ScrollRegionContainer = function ScrollRegionContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      props = _objectWithoutPropertiesLoose(_ref, _excluded);
  return React.createElement(React.Fragment, null, render(useScrollRegion(props)));
};
ScrollRegionContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  containerRef: PropTypes.any.isRequired,
  dependency: PropTypes.any
};

export { ScrollRegionContainer, useScrollRegion };
