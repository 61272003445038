import {
  Fragment,
  Children,
  useState,
  useMemo,
  createElement,
  isValidElement,
} from 'react';
import { useRootEngine } from 'flume';
// import { detailedDiff } from 'deep-object-diff';

import {
  createGraphConfig,
  createGraphEngine,
  getNodesFromStorage,
  setNodesToStorage,
} from '../utils';

/*
  TODO: flume:
    - children, allow multiple inputs
    - multi-selection
    - theming
*/

// graph config
export function useGraphNodes({ nodes, ports, resolvePort, resolveNode }, context) {
  const [currentNodes, setNodes] = useState(getNodesFromStorage());
  const rootNode = useMemo(() =>
    Object.values(currentNodes).find(({ root }) => root),
  );
  const config = useMemo(() => createGraphConfig({ nodes, ports }), [nodes, ports]);
  const engine = useMemo(
    () => createGraphEngine({ config, resolvePort, resolveNode }),
    [config, resolvePort, resolveNode],
  );
  const root = useRootEngine(currentNodes, engine, context);
  const [viewGraphMeta, setGraphMeta] = useState();

  // graph hook methods
  const {
    logLogic,
    saveLogic,
    loadLogic,
    resetLogic,
    toggleGraph,
    updateNodes,
    renderRoot,
  } = useMemo(
    () => ({
      renderRoot: () => {
        if (root.node && isValidElement(root.node)) {
          return root.node;
        } else if (Array.isArray(root.node)) {
          return createElement(
            Fragment,
            undefined,
            Children.toArray(root.node.filter(isValidElement)),
          );
        }

        return null;
      },
      updateNodes(incomingNodes, isPersistent = true) {
        setNodes(incomingNodes);

        if (isPersistent) {
          setNodesToStorage(incomingNodes);
        }
      },
      toggleGraph: () => {
        setGraphMeta(!viewGraphMeta);
      },
      saveLogic: () => {
        setNodesToStorage(currentNodes);
      },
      loadLogic: () => {
        setNodes(getNodesFromStorage());
      },
      resetLogic: () => {
        setNodesToStorage({});
        setNodes({});
      },
      logLogic: () => {
        console.log(currentNodes);
        console.log(engine);
        console.log(root);
      },
    }),
    [currentNodes, engine, root, viewGraphMeta],
  );

  return useMemo(
    () => ({
      nodes: currentNodes,
      rootNode,
      root,
      config,
      engine,
      setNodes,
      logLogic,
      saveLogic,
      loadLogic,
      resetLogic,
      renderRoot,
      toggleGraph,
      updateNodes,
      viewGraphMeta,
    }),
    [
      currentNodes,
      rootNode,
      root,
      config,
      engine,
      setNodes,
      logLogic,
      saveLogic,
      loadLogic,
      resetLogic,
      renderRoot,
      toggleGraph,
      updateNodes,
      viewGraphMeta,
    ],
  );
}
