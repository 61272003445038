/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import { useSelection } from '@zendeskgarden/container-selection';
import React from 'react';
import PropTypes from 'prop-types';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

var _excluded$1 = ["role"],
    _excluded2 = ["role", "selectedAriaKey"];
function useButtonGroup(options) {
  var _useSelection = useSelection(options),
      selectedItem = _useSelection.selectedItem,
      focusedItem = _useSelection.focusedItem,
      getContainerProps = _useSelection.getContainerProps,
      getItemProps = _useSelection.getItemProps;
  var _getGroupProps = function getGroupProps(_temp) {
    var _ref = _temp === void 0 ? {} : _temp,
        _ref$role = _ref.role,
        role = _ref$role === void 0 ? 'group' : _ref$role,
        other = _objectWithoutPropertiesLoose(_ref, _excluded$1);
    return _extends({
      role: role,
      'data-garden-container-id': 'containers.buttongroup',
      'data-garden-container-version': '0.3.14'
    }, other);
  };
  var _getButtonProps = function getButtonProps(_temp2) {
    var _ref2 = _temp2 === void 0 ? {} : _temp2,
        _ref2$role = _ref2.role,
        role = _ref2$role === void 0 ? 'button' : _ref2$role,
        _ref2$selectedAriaKey = _ref2.selectedAriaKey,
        selectedAriaKey = _ref2$selectedAriaKey === void 0 ? 'aria-pressed' : _ref2$selectedAriaKey,
        other = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return _extends({
      role: role,
      selectedAriaKey: selectedAriaKey
    }, other);
  };
  return {
    selectedItem: selectedItem,
    focusedItem: focusedItem,
    getGroupProps: function getGroupProps(props) {
      return getContainerProps(_getGroupProps(props));
    },
    getButtonProps: function getButtonProps(props) {
      return getItemProps(_getButtonProps(props), 'getButtonProps');
    }
  };
}

var _excluded = ["children", "render"];
var ButtonGroupContainer = function ButtonGroupContainer(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
  return React.createElement(React.Fragment, null, render(useButtonGroup(options)));
};
ButtonGroupContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  focusedItem: PropTypes.any,
  selectedItem: PropTypes.any,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func
};

export { ButtonGroupContainer, useButtonGroup };
